import { rem } from 'src/core/theme/utilities';
import styled from 'styled-components';

export const PageContainer = styled('div')`
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  ${({ theme: { isMobile } }) =>
    isMobile &&
    `
  padding: 0 ${rem(32)};

  `}
`;
export const Link = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
