import { Footer, HeaderLogo } from 'src/molecules';
import { Typography } from 'src/atoms';
import * as Styled from './NotFoundStyled';
import { FC, ReactElement } from 'react';
import Theme, { ThemeVariant } from 'src/core/theme/Theme';

interface Props {
  message?: ReactElement | string | null;
  subMessage?: ReactElement | string | null;
  hideHeader?: boolean;
  hideFooter?: boolean;
  isChild?: boolean;
}

export const NotFound: FC<Props> = ({
  message = 'Page not found',
  subMessage = 'click here to go home',
  hideHeader,
  hideFooter,
}) => {
  const goHome = () => {
    window.location.replace('/match');
  };
  return (
    <Theme color={ThemeVariant.OFFWHITE}>
      {!hideHeader && <HeaderLogo />}

      <Styled.Container>
        <Typography.Title>{message}</Typography.Title>
        <Styled.LinkContainer>
          <Styled.NotFoundSubtitle onClick={goHome}>{subMessage}</Styled.NotFoundSubtitle>
        </Styled.LinkContainer>
        {!hideFooter && <Footer />}
      </Styled.Container>
    </Theme>
  );
};
