import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
  from  { 
      opacity: 0; 
      transform:  translate(0px,15px); 
    }
  to { 
      opacity: 1; 
      transform:  translate(0px,0px);
    }
`;
export const FadeContainer = styled.div`
  animation: ${css`
    ${fadeIn} ease .7s;
  `};
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards;
  opacity: 0;
  opacity: 1\9;
`;
