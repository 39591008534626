import styled from 'styled-components';

import { flexCenter } from 'src/styles/layout';
import { rem } from 'src/core/theme/utilities';
import { themeColors } from 'src/core/theme/Theme';
import { Body } from 'src/atoms/Typography/Body';

export const Container = styled('div')`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const OptionContainer = styled('label')`
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  min-height: ${rem(30)};
  width: auto;

  > div {
    margin-right: ${rem(16)};
  }

  & + & {
    margin-top: ${rem(24)};
  }
`;

export const OptionDescription = styled(Body)`
  margin: ${rem(8)} 0 ${rem(32)} ${rem(32)};
  color: ${themeColors['Greige']};
`;

export const RadioOption = styled('input').attrs({ type: 'radio' })`
  ${flexCenter}

  appearance: none;
  background-color: ${({ theme }) => theme.colors['Medium Beige']};
  border-radius: 50%;
  color: currentColor;
  font: inherit;
  height: ${rem(16)};
  margin: 0;
  margin-right: ${rem(16)};
  width: ${rem(16)};

  :checked {
    border: ${rem(4)} solid ${({ theme }) => theme.colors['Dark Gray']};
  }

  ::before {
    border-radius: inherit;
    box-shadow: inset ${rem(16)} ${rem(16)} ${({ theme }) => theme.colors['White']};
    content: '';
    height: ${rem(8)};
    transform: scale(0);
    width: ${rem(8)};
  }

  :checked::before {
    transform: scale(1);
  }
`;
