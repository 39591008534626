import { Checkmark } from 'src/atoms/Icons/Icons';
import { themeColors } from 'src/core/theme/Theme';
import { rem } from 'src/core/theme/utilities';
import styled from 'styled-components';

// TODO: Taken from altruist-frontend... this really needs a re-do
export const Container = styled.div`
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ${rem(16)};
  height: ${rem(16)};
`;

export const StyledIcon = styled(Checkmark)`
  display: none;
  width: ${rem(14)};
  height: ${rem(14)};
  justify-content: center;
  align-items: center;
`;

export const CheckBox = styled.input`
  position: absolute;
  top: -1px;
  left: -1px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin: 0;

  & ~ svg {
    position: absolute;
    pointer-events: none;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: ${themeColors['Medium Beige']};
    border: 1px solid transparent;
    border-radius: 3px;
  }

  &:checked::before {
    border-color: ${themeColors['Dark Greige']};
    background: ${themeColors['Dark Greige']};
  }

  &:checked ~ svg {
    display: block;
    path {
      stroke: ${themeColors['White']};
    }
  }
`;
