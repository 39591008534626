import { useCallback, useEffect, useState } from 'react';

type UseScrollTrigger = (distance?: number) => boolean;

export const useScrollTrigger: UseScrollTrigger = (distance = 0) => {
  const [show, setShow] = useState(false);

  const handleScroll = useCallback(() => {
    if (distance === 0) {
      return;
    }

    const scrolled = window.scrollY;

    if (scrolled >= distance !== show) {
      setShow((prev) => !prev);
    }
  }, [distance, show]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return show;
};
