export const rem = (size: number | string) => {
  const numberSize = typeof size === 'string' ? parseInt(size) : size;
  return `${numberSize / 16}rem`;
};

export const clearfix = (parent = '&') => ({
  [`${parent}::after`]: {
    clear: 'both',
    content: '""',
    display: 'table',
  },
});

export const DEFAULT_SVG_PROPS = {
  width: '1em',
  height: '1em',
  fill: 'currentColor',
  xmlns: 'http://www.w3.org/2000/svg',
};

export const accelerate = `
  backface-visibility: hidden;
  perspective: 1000;
`;

export const speed = {
  xxfast: '0.08s',
  xfast: '0.14s',
  fast: '0.34s',
  medium: '0.42s',
  slow: '0.64s',
  xslow: '0.84s',
};

export const easing = {
  standard: 'cubic-bezier(0.4, 0, 0.2, 1)',
  decelerate: 'cubic-bezier(0, 0, 0.2, 1)',
  accelerate: 'cubic-bezier(0.4, 0, 1, 1)',
  loop: 'cubic-bezier(0.86, 0.38, 0.24, 0.61)',
};

export const transition = {
  opacity: `opacity ${speed.fast} ${easing.standard}`,
  padding: `padding ${speed.medium} ${easing.standard}`,
  border: `border-color ${speed.xfast} ${easing.accelerate}`,
  background: `background ${speed.fast} ${easing.standard}`,
  transform: `transform ${speed.fast} ${easing.accelerate}`,
};

export const size = {
  xsmall: 320,
  small: 500,
  medium: 768,
  large: 1048,
  xlarge: 1200,
  xxlarge: 2560,
};
