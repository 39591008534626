import { FC, forwardRef, Ref, TextareaHTMLAttributes } from 'react';

import * as Styled from './TextareaStyled';

export const TEST_ID = 'Textarea-Input';

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  ref?: Ref<HTMLTextAreaElement>;
}

export const Textarea: FC<TextareaProps> = forwardRef((props, ref) => (
  <Styled.TextareaInput {...props} ref={ref} data-testid='Textarea-Input' />
));
