import { theme } from 'src/core/theme/Theme';
import { rem } from 'src/core/theme/utilities';
import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'Ivar Headline Medium';
  font-style: normal;
  font-size: ${rem(52)};
  line-height: 60px;
  /* or 96% */

  text-align: center;

  /* Dark Greige */
  color: ${theme.colors['Dark Greige']};
`;
