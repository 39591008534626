import styled from 'styled-components';

import { rem } from 'src/core/theme/utilities';
import { RadioOption } from 'src/molecules/CheckList/CheckListStyled';

import { containerStyles } from '../CommonStyled';

export const Container = styled('label')`
  ${containerStyles}
  height: ${rem(64)};
  border-radius: ${rem(100)};
  justify-content: space-between;
  padding: ${rem(24)} ${rem(32)};
`;

export const Radio = styled(RadioOption)`
  margin: 0;
`;
