import styled from 'styled-components';

import { rem } from 'src/core/theme/utilities';
import { commonInput } from 'src/styles/inputs';
import { themeColors } from 'src/core/theme/Theme';

export const TextareaInput = styled('textarea')`
  ${commonInput}
  font-size: ${rem(16)};
  color: ${themeColors['Dark Greige']};
  background-color: ${themeColors['Off white']};
  height: ${rem(90)};
  resize: none;
  font: inherit;
`;
