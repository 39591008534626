import styled, { css } from 'styled-components';

import { BodyStyles } from 'src/atoms/Typography/Body';
import { flexCenter } from 'src/styles/layout';
import { rem } from 'src/core/theme/utilities';
import { theme } from 'src/core/theme/Theme';

const bodyStyles = BodyStyles({ size: 'DEFAULT' });

export const varStyles = {
  '--active-body-color': theme.colors['Dark Greige'],
  '--active-border-color': theme.colors['Greige'],
  '--active-border-size': '2px',
  '--active-title-weight': 500,
};

export const containerStyles = css`
  ${flexCenter}

  border: var(--active-border-size, 1px) solid var(--active-border-color, ${({ theme }) =>
    theme.colors['Medium Beige']});
  box-sizing: border-box;
  cursor: pointer;
  height: auto;
  max-width: ${rem(364)};
  transition: all 100ms ease-out;
  width: 100%;

  :active {
    transform: scale(0.95);
  }
`;

export const Title = styled('span')`
  ${bodyStyles}

  font-weight: var(--active-title-weight, 400);
`;

export const Body = styled('span')`
  ${bodyStyles}

  color: var(--active-body-color, ${({ theme }) => theme.colors['Greige']});
  font-size: ${rem(14)};
  line-height: ${rem(24)};
`;
