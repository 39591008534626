import { useTreatments } from '@splitsoftware/splitio-react';

export const useGetFeatures = (featureNames: string[]) => {
  const treatments = useTreatments(featureNames);
  const features = Object.keys(treatments).map((t) => ({
    name: t,
    value: treatments[t].treatment,
  }));
  return {
    features,
  };
};
