import { ReactElement } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useAuthContext } from 'src/core/AuthProvider';
import { usePreviewAdvisorQuery } from 'src/graphql/generated';

export const ProtectedRoute = ({
  children,
}: {
  children: ReactElement<Record<string, unknown>> | null;
}) => {
  const { setStoken, setApiUrl } = useAuthContext();
  const [searchParams] = useSearchParams();
  const stoken = searchParams.get('stoken') || '';
  const apiUrl = searchParams.get('apiUrl') || '';

  const { data: advisor, loading } = usePreviewAdvisorQuery({
    variables: { stoken, apiUrl },
    onCompleted: () => {
      document.title = 'Match | Preview';
      setStoken(stoken);
      setApiUrl(apiUrl);
    },
  });

  if (loading) {
    return null;
  }
  if (!advisor) {
    return <Navigate to='/coming-soon' replace />;
  }
  return children;
};
