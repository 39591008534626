import { FC } from 'react';
import { altruistDisclosureStatement } from 'src/core/constants';
import * as Styled from './DisclosureStatementStyled';

export const DisclosureStatement: FC = () => {
  return (
    <Styled.Disclosure data-testid='DisclosureStatement-wrapper'>
      {altruistDisclosureStatement}
      Both documents are available at{' '}
      <Styled.LegalLink target='_blank' href='https://altruist.com/legal'>
        https://altruist.com/legal.
      </Styled.LegalLink>
    </Styled.Disclosure>
  );
};
