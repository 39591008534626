import { SplitTreatments } from '@splitsoftware/splitio-react';
import { FC, useContext, createContext } from 'react';
import { useGetFeatures } from 'src/hooks/useGetFeatures';

export enum FeatureFlags {
  CONSUMER_MARKETPLACE = 'consumerMarketplace',
  LANDING_PAGE_SOCIAL_PROOF = 'advisorMarketplaceLPSocialProof',
  MATCH_DIRECTORY = 'matchDirectory',
  MATCH_QUIZ_EXPERIENCE = 'matchQuizExperience',
}

export enum FEATURE_VALUE {
  ON = 'on',
  OFF = 'off',
}

export const featureList = Object.keys(FeatureFlags).map(
  (f) => FeatureFlags[f as keyof typeof FeatureFlags],
);

export type Feature = {
  name: string;
  value: string;
};

export interface FeatureContextProps {
  features: Feature[];
  isFeatureOn: (featureName: FeatureFlags, value: FEATURE_VALUE) => boolean;
}

export const initialContextValues: FeatureContextProps = {
  features: [],
  isFeatureOn: () => false,
};
export const FeatureContext = createContext(initialContextValues);

export const FeatureProvider: FC = ({ children }) => {
  const { features } = useGetFeatures(featureList);
  return (
    <SplitTreatments names={featureList}>
      {({ isReady }) => {
        if (!isReady) {
          return null;
        }
        const isFeatureOn = (featureName: FeatureFlags, value: FEATURE_VALUE) =>
          !!features.find((f) => f.value === value && f.name === featureName);
        return (
          <FeatureContext.Provider value={{ isFeatureOn, features }}>
            {children}
          </FeatureContext.Provider>
        );
      }}
    </SplitTreatments>
  );
};

export const useFeatureContext = () => useContext(FeatureContext);
