import { themeColors } from 'src/core/theme/Theme';
import { rem } from 'src/core/theme/utilities';
import styled, { css } from 'styled-components';
import { BUTTON_COLOR, BUTTON_SIZES } from './Button';

const BUTTON_THEMES = {
  'Dark Green': css`
    color: ${themeColors['White']};
    background: ${themeColors['Dark Green']};
  `,
  Greige: css`
    color: ${themeColors['Beige']};
    background: ${themeColors['Dark Greige']};
  `,
  Beige: css`
    color: ${themeColors['Dark Greige']};
    background: ${themeColors['Medium Beige']};
  `,
  Disabled: css`
    color: ${themeColors['20Black']};
    background: ${themeColors['40Black']};
  `,
  'Bright Green': css`
    color: ${themeColors['White']};
    background: ${themeColors['Bright Green']};
  `,
};

export const FlatButton = styled.button<{
  variant: BUTTON_SIZES;
  disabled: boolean;
  color: BUTTON_COLOR;
}>`
  border: none;
  border-radius: ${rem(50)};
  padding: ${rem(20)} ${rem(32)};
  font: inherit;

  ${({ variant }) => {
    if (variant === 'LRG') {
      return `
      height: ${rem(64)};
      font-size: ${rem(18)};
      padding: ${rem(22)} ${rem(32)};
      `;
    } else if (variant === 'SML') {
      return `
      height: ${rem(48)};
      font-size: ${rem(14)};
      padding: ${rem(16)} ${rem(32)};
      `;
    } else if (variant === 'DEFAULT') {
      return `
      height: ${rem(58)};
      font-size: ${rem(14)};
      padding: ${rem(16)} ${rem(32)};
      `;
    }
  }};

  ${({ color }) => {
    return BUTTON_THEMES[color as keyof typeof BUTTON_THEMES];
  }}

  ${({ disabled }) => {
    return disabled && BUTTON_THEMES.Disabled;
  }}
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IconSpan = styled.div`
  margin-right: ${rem(5)};
`;
