import { createContext, FC, useContext, useState } from 'react';

export interface AuthContextValues {
  stoken: string;
  apiUrl: string;
  setStoken: React.Dispatch<React.SetStateAction<string>>;
  setApiUrl: React.Dispatch<React.SetStateAction<string>>;
}

const defaultFunc: React.Dispatch<React.SetStateAction<string>> = () => null;

export const initialContextValues: AuthContextValues = {
  stoken: '',
  setStoken: defaultFunc,
  apiUrl: '',
  setApiUrl: defaultFunc,
};

export const AuthContext = createContext(initialContextValues);

export const AuthProvider: FC = ({ children }) => {
  const [stoken, setStoken] = useState<string>('');
  const [apiUrl, setApiUrl] = useState<string>('');
  const value: AuthContextValues = {
    stoken,
    setStoken,
    apiUrl,
    setApiUrl,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
