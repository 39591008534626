import { FC } from 'react';

import { Checkbox } from 'src/atoms';

import { Body, Title, varStyles } from '../CommonStyled';
import * as Styled from './CardStyled';

export const testId = 'selectable-card';

interface CardProps {
  isSelected: boolean;
  onChange: (checked: boolean) => void;
  title: string;
  body?: string;
}

export const Card: FC<CardProps> = ({ body = null, isSelected, onChange, title }) => {
  const style = !isSelected ? {} : varStyles;

  return (
    <Styled.Container data-testid={testId} style={style}>
      <Styled.TopRow>
        <Title>{title}</Title>
        <Checkbox checked={isSelected} onChange={(e) => onChange(e.target.checked)} />
      </Styled.TopRow>
      {body && (
        <Styled.BottomRow>
          <Body>{body}</Body>
        </Styled.BottomRow>
      )}
    </Styled.Container>
  );
};
