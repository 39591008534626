import { GraphQLResolveInfo } from 'graphql';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw'
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AdvisorDraft = {
  readonly __typename?: 'AdvisorDraft';
  readonly id: Scalars['ID'];
  readonly status: Scalars['String'];
};

export type AdvisorMarketplaceDraft = {
  readonly __typename?: 'AdvisorMarketplaceDraft';
  readonly addressCity: Scalars['String'];
  readonly addressState: Scalars['String'];
  readonly advisor: AdvisorDraft;
  readonly advisorDescription: Scalars['String'];
  readonly advisorId?: Maybe<Scalars['String']>;
  readonly businessDescription: Scalars['String'];
  readonly calendarLink: Scalars['String'];
  readonly certifications: ReadonlyArray<Certification>;
  readonly feeTypes: ReadonlyArray<FeeTypeObjOutput>;
  readonly firstName: Scalars['String'];
  readonly formAdvLink: Scalars['String'];
  readonly iardCrd: Scalars['String'];
  readonly id?: Maybe<Scalars['ID']>;
  readonly idealClients: ReadonlyArray<IdealClient>;
  readonly images: ReadonlyArray<ImageMultiPartOutput>;
  readonly instantBook: Scalars['Boolean'];
  readonly isPublished?: Maybe<Scalars['Boolean']>;
  readonly lastName: Scalars['String'];
  readonly meetType: ReadonlyArray<MEETING_PREFERENCE>;
  readonly minimumInvestment: MinimumInvestment;
  readonly organizationIardCrd: Scalars['String'];
  readonly organizationImage: ImageMultiPartOutput;
  readonly organizationName: Scalars['String'];
  readonly profileImage: ImageMultiPartOutput;
  readonly publicId?: Maybe<Scalars['ID']>;
  readonly registration: REGISTRATION;
  readonly registrationStates: ReadonlyArray<STATE_INITIAL>;
  readonly services: ReadonlyArray<SERVICES>;
};

export type AdvisorMarketplaceProfile = {
  readonly __typename?: 'AdvisorMarketplaceProfile';
  readonly addressCity: Scalars['String'];
  readonly addressState: Scalars['String'];
  readonly advisor: AdvisorDraft;
  readonly advisorDescription: Scalars['String'];
  readonly advisorId?: Maybe<Scalars['String']>;
  readonly businessDescription: Scalars['String'];
  readonly calendarLink: Scalars['String'];
  readonly certifications: ReadonlyArray<Certification>;
  readonly feeTypes: ReadonlyArray<FeeTypeObjOutput>;
  readonly firstName: Scalars['String'];
  readonly formAdvLink: Scalars['String'];
  readonly iardCrd: Scalars['String'];
  readonly id?: Maybe<Scalars['ID']>;
  readonly idealClients: ReadonlyArray<IdealClient>;
  readonly images: ReadonlyArray<ImageMultiPartOutput>;
  readonly instantBook: Scalars['Boolean'];
  readonly isPublished: Scalars['Boolean'];
  readonly lastName: Scalars['String'];
  readonly meetType: ReadonlyArray<MEETING_PREFERENCE>;
  readonly minimumInvestment: MinimumInvestment;
  readonly organizationIardCrd: Scalars['String'];
  readonly organizationImage: ImageMultiPartOutput;
  readonly organizationName: Scalars['String'];
  readonly profileImage: ImageMultiPartOutput;
  readonly publicId?: Maybe<Scalars['ID']>;
  readonly registration: REGISTRATION;
  readonly registrationStates: ReadonlyArray<STATE_INITIAL>;
  readonly services: ReadonlyArray<SERVICES>;
};

export type AdvisorMarketplacePublicProfile = {
  readonly __typename?: 'AdvisorMarketplacePublicProfile';
  readonly addressCity: Scalars['String'];
  readonly addressState: Scalars['String'];
  readonly advisorDescription: Scalars['String'];
  readonly businessDescription: Scalars['String'];
  readonly calendarLink: Scalars['String'];
  readonly certifications: ReadonlyArray<Certification>;
  readonly feeTypes: ReadonlyArray<FeeTypeObjOutput>;
  readonly firstName: Scalars['String'];
  readonly formAdvLink: Scalars['String'];
  readonly iardCrd: Scalars['String'];
  readonly idealClients: ReadonlyArray<IdealClient>;
  readonly images: ReadonlyArray<ImageMultiPartOutput>;
  readonly instantBook: Scalars['Boolean'];
  readonly isPublished: Scalars['Boolean'];
  readonly lastName: Scalars['String'];
  readonly meetType: ReadonlyArray<MEETING_PREFERENCE>;
  readonly minimumInvestment: MinimumInvestment;
  readonly organizationIardCrd: Scalars['String'];
  readonly organizationImage: ImageMultiPartOutput;
  readonly organizationName: Scalars['String'];
  readonly profileImage: ImageMultiPartOutput;
  readonly publicId?: Maybe<Scalars['ID']>;
  readonly registration: REGISTRATION;
  readonly registrationStates: ReadonlyArray<STATE_INITIAL>;
  readonly services: ReadonlyArray<SERVICES>;
};

export type AdvisorSearchInput = {
  readonly age: Scalars['Int'];
  readonly aum: Scalars['String'];
  readonly email?: InputMaybe<Scalars['String']>;
  readonly income: Scalars['String'];
  readonly services: ReadonlyArray<InputMaybe<SERVICES>>;
  readonly state: Scalars['String'];
  readonly timeframe: Scalars['String'];
};

export type Certification = {
  readonly __typename?: 'Certification';
  readonly abbreviation: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export enum FEE_TYPES {
  ASSET = 'ASSET',
  FIXED = 'FIXED',
  HOURLY = 'HOURLY'
}

export type FeeTypeObjOutput = {
  readonly __typename?: 'FeeTypeObjOutput';
  readonly feeType: FEE_TYPES;
};

export enum GOALS {
  DEBT = 'DEBT',
  EDUCATION = 'EDUCATION',
  FAMILY = 'FAMILY',
  HOME = 'HOME',
  OTHER = 'OTHER',
  PURCHASE = 'PURCHASE',
  RETIREMENT = 'RETIREMENT'
}

export enum INDUSTRIES {
  AGRICULTURE = 'AGRICULTURE',
  CONSTRUCTION = 'CONSTRUCTION',
  EDUCATION = 'EDUCATION',
  ENERGY = 'ENERGY',
  ENTERTAINMENT = 'ENTERTAINMENT',
  FOOD_AND_BEVERAGE = 'FOOD_AND_BEVERAGE',
  GOVERNMENT = 'GOVERNMENT',
  HEALTHCARE = 'HEALTHCARE',
  HOSPITALITY = 'HOSPITALITY',
  MANUFACTURING = 'MANUFACTURING',
  NON_PROFIT = 'NON_PROFIT',
  NOT_EMPLOYED = 'NOT_EMPLOYED',
  OTHER = 'OTHER',
  REAL_ESTATE = 'REAL_ESTATE',
  RETAIL = 'RETAIL',
  RETIRED = 'RETIRED',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  STUDENT = 'STUDENT',
  TECHNOLOGY = 'TECHNOLOGY',
  TRANSPORTATION = 'TRANSPORTATION'
}

export type IdealClient = {
  readonly __typename?: 'IdealClient';
  readonly details: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type ImageMultiPartOutput = {
  readonly __typename?: 'ImageMultiPartOutput';
  readonly id: Scalars['String'];
  readonly url: Scalars['String'];
};

export enum MEETING_PREFERENCE {
  IN_PERSON = 'IN_PERSON',
  VIRTUAL = 'VIRTUAL'
}

export type MatchConnectParams = {
  readonly advisorProfileId: Scalars['ID'];
  readonly email: Scalars['String'];
};

export type MatchRequestDto = {
  readonly __typename?: 'MatchRequestDto';
  readonly advisorProfileId: Scalars['ID'];
  readonly age: Scalars['Int'];
  readonly aum: Scalars['ID'];
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly goals: ReadonlyArray<Maybe<GOALS>>;
  readonly goalsNote?: Maybe<Scalars['String']>;
  readonly income: Scalars['ID'];
  readonly instantBook: Scalars['Boolean'];
  readonly lastName: Scalars['String'];
  readonly message: Scalars['String'];
  readonly optIn: Scalars['Boolean'];
  readonly services: ReadonlyArray<Maybe<Scalars['String']>>;
  readonly timeframe: Scalars['String'];
  readonly usCodeState: Scalars['String'];
  readonly workIndustry: INDUSTRIES;
  readonly workIndustryNote?: Maybe<Scalars['String']>;
};

export type MatchRequestInput = {
  readonly advisorProfileId: Scalars['ID'];
  readonly age: Scalars['Int'];
  readonly aum: Scalars['ID'];
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly goals: ReadonlyArray<InputMaybe<GOALS>>;
  readonly goalsNote?: InputMaybe<Scalars['String']>;
  readonly income: Scalars['ID'];
  readonly instantBook: Scalars['Boolean'];
  readonly lastName: Scalars['String'];
  readonly message: Scalars['String'];
  readonly optIn: Scalars['Boolean'];
  readonly services: ReadonlyArray<InputMaybe<Scalars['String']>>;
  readonly timeframe: Scalars['String'];
  readonly usCodeState: Scalars['String'];
  readonly workIndustry: INDUSTRIES;
  readonly workIndustryNote?: InputMaybe<Scalars['String']>;
};

export type MetaData = {
  readonly __typename?: 'MetaData';
  readonly certifications: ReadonlyArray<Maybe<Certification>>;
  readonly idealClients: ReadonlyArray<Maybe<IdealClient>>;
  readonly minimumInvestments: MinimumInvestment;
};

export type MinimumInvestment = {
  readonly __typename?: 'MinimumInvestment';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly value: Scalars['Int'];
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly acceptMatchRequest: Scalars['Boolean'];
  readonly advisorSearch: ReadonlyArray<Maybe<AdvisorMarketplaceProfile>>;
  readonly declineMatchRequest: Scalars['Boolean'];
  readonly requestMatch: MatchRequestDto;
};


export type MutationacceptMatchRequestArgs = {
  matchRequestId: Scalars['ID'];
};


export type MutationadvisorSearchArgs = {
  input?: InputMaybe<AdvisorSearchInput>;
};


export type MutationdeclineMatchRequestArgs = {
  matchRequestId: Scalars['ID'];
};


export type MutationrequestMatchArgs = {
  input: MatchRequestInput;
};

export type Query = {
  readonly __typename?: 'Query';
  readonly advisorProfile: AdvisorMarketplaceProfile;
  readonly advisorProfileByPublicId: AdvisorMarketplaceProfile;
  readonly getMatchConnect: MatchRequestDto;
  readonly metaData: MetaData;
  readonly previewAdvisor: AdvisorMarketplaceDraft;
};


export type QueryadvisorProfileArgs = {
  id: Scalars['String'];
};


export type QueryadvisorProfileByPublicIdArgs = {
  publicProfileId: Scalars['String'];
};


export type QuerygetMatchConnectArgs = {
  input: MatchConnectParams;
};


export type QuerypreviewAdvisorArgs = {
  apiUrl: Scalars['String'];
  stoken: Scalars['String'];
};

export enum REGISTRATION {
  SEC = 'SEC',
  STATE = 'STATE'
}

export enum SERVICES {
  BUSINESS = 'BUSINESS',
  FINANCIAL = 'FINANCIAL',
  INVESTMENT = 'INVESTMENT',
  RETIREMENT = 'RETIREMENT',
  TAX = 'TAX',
  WEALTH = 'WEALTH'
}

export enum STATE_INITIAL {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY'
}



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AdvisorDraft: ResolverTypeWrapper<AdvisorDraft>;
  AdvisorMarketplaceDraft: ResolverTypeWrapper<AdvisorMarketplaceDraft>;
  AdvisorMarketplaceProfile: ResolverTypeWrapper<AdvisorMarketplaceProfile>;
  AdvisorMarketplacePublicProfile: ResolverTypeWrapper<AdvisorMarketplacePublicProfile>;
  AdvisorSearchInput: AdvisorSearchInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Certification: ResolverTypeWrapper<Certification>;
  FEE_TYPES: FEE_TYPES;
  FeeTypeObjOutput: ResolverTypeWrapper<FeeTypeObjOutput>;
  GOALS: GOALS;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  INDUSTRIES: INDUSTRIES;
  IdealClient: ResolverTypeWrapper<IdealClient>;
  ImageMultiPartOutput: ResolverTypeWrapper<ImageMultiPartOutput>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  MEETING_PREFERENCE: MEETING_PREFERENCE;
  MatchConnectParams: MatchConnectParams;
  MatchRequestDto: ResolverTypeWrapper<MatchRequestDto>;
  MatchRequestInput: MatchRequestInput;
  MetaData: ResolverTypeWrapper<MetaData>;
  MinimumInvestment: ResolverTypeWrapper<MinimumInvestment>;
  Mutation: ResolverTypeWrapper<{}>;
  Query: ResolverTypeWrapper<{}>;
  REGISTRATION: REGISTRATION;
  SERVICES: SERVICES;
  STATE_INITIAL: STATE_INITIAL;
  String: ResolverTypeWrapper<Scalars['String']>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AdvisorDraft: AdvisorDraft;
  AdvisorMarketplaceDraft: AdvisorMarketplaceDraft;
  AdvisorMarketplaceProfile: AdvisorMarketplaceProfile;
  AdvisorMarketplacePublicProfile: AdvisorMarketplacePublicProfile;
  AdvisorSearchInput: AdvisorSearchInput;
  Boolean: Scalars['Boolean'];
  Certification: Certification;
  FeeTypeObjOutput: FeeTypeObjOutput;
  ID: Scalars['ID'];
  IdealClient: IdealClient;
  ImageMultiPartOutput: ImageMultiPartOutput;
  Int: Scalars['Int'];
  MatchConnectParams: MatchConnectParams;
  MatchRequestDto: MatchRequestDto;
  MatchRequestInput: MatchRequestInput;
  MetaData: MetaData;
  MinimumInvestment: MinimumInvestment;
  Mutation: {};
  Query: {};
  String: Scalars['String'];
};

export type AdvisorDraftResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdvisorDraft'] = ResolversParentTypes['AdvisorDraft']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdvisorMarketplaceDraftResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdvisorMarketplaceDraft'] = ResolversParentTypes['AdvisorMarketplaceDraft']> = {
  addressCity?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addressState?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  advisor?: Resolver<ResolversTypes['AdvisorDraft'], ParentType, ContextType>;
  advisorDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  advisorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  businessDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  calendarLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  certifications?: Resolver<ReadonlyArray<ResolversTypes['Certification']>, ParentType, ContextType>;
  feeTypes?: Resolver<ReadonlyArray<ResolversTypes['FeeTypeObjOutput']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  formAdvLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  iardCrd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  idealClients?: Resolver<ReadonlyArray<ResolversTypes['IdealClient']>, ParentType, ContextType>;
  images?: Resolver<ReadonlyArray<ResolversTypes['ImageMultiPartOutput']>, ParentType, ContextType>;
  instantBook?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPublished?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  meetType?: Resolver<ReadonlyArray<ResolversTypes['MEETING_PREFERENCE']>, ParentType, ContextType>;
  minimumInvestment?: Resolver<ResolversTypes['MinimumInvestment'], ParentType, ContextType>;
  organizationIardCrd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationImage?: Resolver<ResolversTypes['ImageMultiPartOutput'], ParentType, ContextType>;
  organizationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profileImage?: Resolver<ResolversTypes['ImageMultiPartOutput'], ParentType, ContextType>;
  publicId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  registration?: Resolver<ResolversTypes['REGISTRATION'], ParentType, ContextType>;
  registrationStates?: Resolver<ReadonlyArray<ResolversTypes['STATE_INITIAL']>, ParentType, ContextType>;
  services?: Resolver<ReadonlyArray<ResolversTypes['SERVICES']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdvisorMarketplaceProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdvisorMarketplaceProfile'] = ResolversParentTypes['AdvisorMarketplaceProfile']> = {
  addressCity?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addressState?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  advisor?: Resolver<ResolversTypes['AdvisorDraft'], ParentType, ContextType>;
  advisorDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  advisorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  businessDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  calendarLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  certifications?: Resolver<ReadonlyArray<ResolversTypes['Certification']>, ParentType, ContextType>;
  feeTypes?: Resolver<ReadonlyArray<ResolversTypes['FeeTypeObjOutput']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  formAdvLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  iardCrd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  idealClients?: Resolver<ReadonlyArray<ResolversTypes['IdealClient']>, ParentType, ContextType>;
  images?: Resolver<ReadonlyArray<ResolversTypes['ImageMultiPartOutput']>, ParentType, ContextType>;
  instantBook?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPublished?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  meetType?: Resolver<ReadonlyArray<ResolversTypes['MEETING_PREFERENCE']>, ParentType, ContextType>;
  minimumInvestment?: Resolver<ResolversTypes['MinimumInvestment'], ParentType, ContextType>;
  organizationIardCrd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationImage?: Resolver<ResolversTypes['ImageMultiPartOutput'], ParentType, ContextType>;
  organizationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profileImage?: Resolver<ResolversTypes['ImageMultiPartOutput'], ParentType, ContextType>;
  publicId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  registration?: Resolver<ResolversTypes['REGISTRATION'], ParentType, ContextType>;
  registrationStates?: Resolver<ReadonlyArray<ResolversTypes['STATE_INITIAL']>, ParentType, ContextType>;
  services?: Resolver<ReadonlyArray<ResolversTypes['SERVICES']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdvisorMarketplacePublicProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdvisorMarketplacePublicProfile'] = ResolversParentTypes['AdvisorMarketplacePublicProfile']> = {
  addressCity?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  addressState?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  advisorDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  businessDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  calendarLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  certifications?: Resolver<ReadonlyArray<ResolversTypes['Certification']>, ParentType, ContextType>;
  feeTypes?: Resolver<ReadonlyArray<ResolversTypes['FeeTypeObjOutput']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  formAdvLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  iardCrd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  idealClients?: Resolver<ReadonlyArray<ResolversTypes['IdealClient']>, ParentType, ContextType>;
  images?: Resolver<ReadonlyArray<ResolversTypes['ImageMultiPartOutput']>, ParentType, ContextType>;
  instantBook?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPublished?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  meetType?: Resolver<ReadonlyArray<ResolversTypes['MEETING_PREFERENCE']>, ParentType, ContextType>;
  minimumInvestment?: Resolver<ResolversTypes['MinimumInvestment'], ParentType, ContextType>;
  organizationIardCrd?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationImage?: Resolver<ResolversTypes['ImageMultiPartOutput'], ParentType, ContextType>;
  organizationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profileImage?: Resolver<ResolversTypes['ImageMultiPartOutput'], ParentType, ContextType>;
  publicId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  registration?: Resolver<ResolversTypes['REGISTRATION'], ParentType, ContextType>;
  registrationStates?: Resolver<ReadonlyArray<ResolversTypes['STATE_INITIAL']>, ParentType, ContextType>;
  services?: Resolver<ReadonlyArray<ResolversTypes['SERVICES']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CertificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Certification'] = ResolversParentTypes['Certification']> = {
  abbreviation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeeTypeObjOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeeTypeObjOutput'] = ResolversParentTypes['FeeTypeObjOutput']> = {
  feeType?: Resolver<ResolversTypes['FEE_TYPES'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdealClientResolvers<ContextType = any, ParentType extends ResolversParentTypes['IdealClient'] = ResolversParentTypes['IdealClient']> = {
  details?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageMultiPartOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImageMultiPartOutput'] = ResolversParentTypes['ImageMultiPartOutput']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MatchRequestDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['MatchRequestDto'] = ResolversParentTypes['MatchRequestDto']> = {
  advisorProfileId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  age?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  aum?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  goals?: Resolver<ReadonlyArray<Maybe<ResolversTypes['GOALS']>>, ParentType, ContextType>;
  goalsNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  income?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  instantBook?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  optIn?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  services?: Resolver<ReadonlyArray<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  timeframe?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  usCodeState?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workIndustry?: Resolver<ResolversTypes['INDUSTRIES'], ParentType, ContextType>;
  workIndustryNote?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetaDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['MetaData'] = ResolversParentTypes['MetaData']> = {
  certifications?: Resolver<ReadonlyArray<Maybe<ResolversTypes['Certification']>>, ParentType, ContextType>;
  idealClients?: Resolver<ReadonlyArray<Maybe<ResolversTypes['IdealClient']>>, ParentType, ContextType>;
  minimumInvestments?: Resolver<ResolversTypes['MinimumInvestment'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MinimumInvestmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['MinimumInvestment'] = ResolversParentTypes['MinimumInvestment']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  acceptMatchRequest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationacceptMatchRequestArgs, 'matchRequestId'>>;
  advisorSearch?: Resolver<ReadonlyArray<Maybe<ResolversTypes['AdvisorMarketplaceProfile']>>, ParentType, ContextType, Partial<MutationadvisorSearchArgs>>;
  declineMatchRequest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationdeclineMatchRequestArgs, 'matchRequestId'>>;
  requestMatch?: Resolver<ResolversTypes['MatchRequestDto'], ParentType, ContextType, RequireFields<MutationrequestMatchArgs, 'input'>>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  advisorProfile?: Resolver<ResolversTypes['AdvisorMarketplaceProfile'], ParentType, ContextType, RequireFields<QueryadvisorProfileArgs, 'id'>>;
  advisorProfileByPublicId?: Resolver<ResolversTypes['AdvisorMarketplaceProfile'], ParentType, ContextType, RequireFields<QueryadvisorProfileByPublicIdArgs, 'publicProfileId'>>;
  getMatchConnect?: Resolver<ResolversTypes['MatchRequestDto'], ParentType, ContextType, RequireFields<QuerygetMatchConnectArgs, 'input'>>;
  metaData?: Resolver<ResolversTypes['MetaData'], ParentType, ContextType>;
  previewAdvisor?: Resolver<ResolversTypes['AdvisorMarketplaceDraft'], ParentType, ContextType, RequireFields<QuerypreviewAdvisorArgs, 'apiUrl' | 'stoken'>>;
};

export type Resolvers<ContextType = any> = {
  AdvisorDraft?: AdvisorDraftResolvers<ContextType>;
  AdvisorMarketplaceDraft?: AdvisorMarketplaceDraftResolvers<ContextType>;
  AdvisorMarketplaceProfile?: AdvisorMarketplaceProfileResolvers<ContextType>;
  AdvisorMarketplacePublicProfile?: AdvisorMarketplacePublicProfileResolvers<ContextType>;
  Certification?: CertificationResolvers<ContextType>;
  FeeTypeObjOutput?: FeeTypeObjOutputResolvers<ContextType>;
  IdealClient?: IdealClientResolvers<ContextType>;
  ImageMultiPartOutput?: ImageMultiPartOutputResolvers<ContextType>;
  MatchRequestDto?: MatchRequestDtoResolvers<ContextType>;
  MetaData?: MetaDataResolvers<ContextType>;
  MinimumInvestment?: MinimumInvestmentResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
};



/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRequestMatchMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ requestMatch })
 *   )
 * })
 */
export const mockRequestMatchMutation = (resolver: ResponseResolver<GraphQLRequest<RequestMatchMutationVariables>, GraphQLContext<RequestMatchMutation>, any>) =>
  graphql.mutation<RequestMatchMutation, RequestMatchMutationVariables>(
    'RequestMatch',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGetMatchConnectQuery((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ getMatchConnect })
 *   )
 * })
 */
export const mockGetMatchConnectQuery = (resolver: ResponseResolver<GraphQLRequest<getMatchConnectQueryVariables>, GraphQLContext<getMatchConnectQuery>, any>) =>
  graphql.query<getMatchConnectQuery, getMatchConnectQueryVariables>(
    'getMatchConnect',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPreviewAdvisorQuery((req, res, ctx) => {
 *   const { stoken, apiUrl } = req.variables;
 *   return res(
 *     ctx.data({ previewAdvisor })
 *   )
 * })
 */
export const mockPreviewAdvisorQuery = (resolver: ResponseResolver<GraphQLRequest<PreviewAdvisorQueryVariables>, GraphQLContext<PreviewAdvisorQuery>, any>) =>
  graphql.query<PreviewAdvisorQuery, PreviewAdvisorQueryVariables>(
    'PreviewAdvisor',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAdvisorProfileQuery((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ advisorProfile })
 *   )
 * })
 */
export const mockAdvisorProfileQuery = (resolver: ResponseResolver<GraphQLRequest<AdvisorProfileQueryVariables>, GraphQLContext<AdvisorProfileQuery>, any>) =>
  graphql.query<AdvisorProfileQuery, AdvisorProfileQueryVariables>(
    'AdvisorProfile',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAdvisorSearchMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ advisorSearch })
 *   )
 * })
 */
export const mockAdvisorSearchMutation = (resolver: ResponseResolver<GraphQLRequest<AdvisorSearchMutationVariables>, GraphQLContext<AdvisorSearchMutation>, any>) =>
  graphql.mutation<AdvisorSearchMutation, AdvisorSearchMutationVariables>(
    'AdvisorSearch',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAdvisorProfileByPublicIdQuery((req, res, ctx) => {
 *   const { publicProfileId } = req.variables;
 *   return res(
 *     ctx.data({ advisorProfileByPublicId })
 *   )
 * })
 */
export const mockAdvisorProfileByPublicIdQuery = (resolver: ResponseResolver<GraphQLRequest<AdvisorProfileByPublicIdQueryVariables>, GraphQLContext<AdvisorProfileByPublicIdQuery>, any>) =>
  graphql.query<AdvisorProfileByPublicIdQuery, AdvisorProfileByPublicIdQueryVariables>(
    'AdvisorProfileByPublicId',
    resolver
  )

export type RequestMatchMutationVariables = Exact<{
  input: MatchRequestInput;
}>;


export type RequestMatchMutation = { readonly __typename?: 'Mutation', readonly requestMatch: { readonly __typename?: 'MatchRequestDto', readonly firstName: string, readonly lastName: string, readonly age: number, readonly usCodeState: string, readonly services: ReadonlyArray<string | null>, readonly income: string, readonly aum: string, readonly email: string, readonly optIn: boolean, readonly advisorProfileId: string, readonly timeframe: string, readonly instantBook: boolean } };

export type getMatchConnectQueryVariables = Exact<{
  input: MatchConnectParams;
}>;


export type getMatchConnectQuery = { readonly __typename?: 'Query', readonly getMatchConnect: { readonly __typename?: 'MatchRequestDto', readonly firstName: string, readonly lastName: string, readonly age: number, readonly usCodeState: string, readonly services: ReadonlyArray<string | null>, readonly income: string, readonly aum: string, readonly email: string, readonly optIn: boolean, readonly advisorProfileId: string, readonly timeframe: string, readonly instantBook: boolean } };

export type PreviewAdvisorQueryVariables = Exact<{
  stoken: Scalars['String'];
  apiUrl: Scalars['String'];
}>;


export type PreviewAdvisorQuery = { readonly __typename?: 'Query', readonly previewAdvisor: { readonly __typename?: 'AdvisorMarketplaceDraft', readonly publicId?: string | null, readonly id?: string | null, readonly advisorId?: string | null, readonly firstName: string, readonly lastName: string, readonly addressCity: string, readonly addressState: string, readonly advisorDescription: string, readonly businessDescription: string, readonly iardCrd: string, readonly calendarLink: string, readonly services: ReadonlyArray<SERVICES>, readonly registrationStates: ReadonlyArray<STATE_INITIAL>, readonly registration: REGISTRATION, readonly isPublished?: boolean | null, readonly meetType: ReadonlyArray<MEETING_PREFERENCE>, readonly organizationName: string, readonly organizationIardCrd: string, readonly formAdvLink: string, readonly instantBook: boolean, readonly advisor: { readonly __typename?: 'AdvisorDraft', readonly id: string, readonly status: string }, readonly certifications: ReadonlyArray<{ readonly __typename?: 'Certification', readonly id: string, readonly name: string, readonly abbreviation: string }>, readonly idealClients: ReadonlyArray<{ readonly __typename?: 'IdealClient', readonly id: string, readonly name: string, readonly details: string }>, readonly profileImage: { readonly __typename?: 'ImageMultiPartOutput', readonly id: string, readonly url: string }, readonly organizationImage: { readonly __typename?: 'ImageMultiPartOutput', readonly id: string, readonly url: string }, readonly images: ReadonlyArray<{ readonly __typename?: 'ImageMultiPartOutput', readonly id: string, readonly url: string }>, readonly minimumInvestment: { readonly __typename?: 'MinimumInvestment', readonly id: string, readonly name: string, readonly value: number }, readonly feeTypes: ReadonlyArray<{ readonly __typename?: 'FeeTypeObjOutput', readonly feeType: FEE_TYPES }> } };

export type AdvisorProfileQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AdvisorProfileQuery = { readonly __typename?: 'Query', readonly advisorProfile: { readonly __typename?: 'AdvisorMarketplaceProfile', readonly publicId?: string | null, readonly id?: string | null, readonly advisorId?: string | null, readonly firstName: string, readonly lastName: string, readonly addressCity: string, readonly addressState: string, readonly advisorDescription: string, readonly businessDescription: string, readonly iardCrd: string, readonly calendarLink: string, readonly services: ReadonlyArray<SERVICES>, readonly registrationStates: ReadonlyArray<STATE_INITIAL>, readonly registration: REGISTRATION, readonly isPublished: boolean, readonly meetType: ReadonlyArray<MEETING_PREFERENCE>, readonly organizationName: string, readonly organizationIardCrd: string, readonly formAdvLink: string, readonly instantBook: boolean, readonly advisor: { readonly __typename?: 'AdvisorDraft', readonly id: string, readonly status: string }, readonly certifications: ReadonlyArray<{ readonly __typename?: 'Certification', readonly id: string, readonly name: string, readonly abbreviation: string }>, readonly idealClients: ReadonlyArray<{ readonly __typename?: 'IdealClient', readonly id: string, readonly name: string, readonly details: string }>, readonly profileImage: { readonly __typename?: 'ImageMultiPartOutput', readonly id: string, readonly url: string }, readonly organizationImage: { readonly __typename?: 'ImageMultiPartOutput', readonly id: string, readonly url: string }, readonly images: ReadonlyArray<{ readonly __typename?: 'ImageMultiPartOutput', readonly id: string, readonly url: string }>, readonly minimumInvestment: { readonly __typename?: 'MinimumInvestment', readonly id: string, readonly name: string, readonly value: number }, readonly feeTypes: ReadonlyArray<{ readonly __typename?: 'FeeTypeObjOutput', readonly feeType: FEE_TYPES }> } };

export type AdvisorSearchMutationVariables = Exact<{
  input?: InputMaybe<AdvisorSearchInput>;
}>;


export type AdvisorSearchMutation = { readonly __typename?: 'Mutation', readonly advisorSearch: ReadonlyArray<{ readonly __typename?: 'AdvisorMarketplaceProfile', readonly id?: string | null, readonly publicId?: string | null, readonly advisorId?: string | null, readonly firstName: string, readonly lastName: string, readonly addressCity: string, readonly addressState: string, readonly advisorDescription: string, readonly businessDescription: string, readonly iardCrd: string, readonly calendarLink: string, readonly services: ReadonlyArray<SERVICES>, readonly registrationStates: ReadonlyArray<STATE_INITIAL>, readonly registration: REGISTRATION, readonly isPublished: boolean, readonly meetType: ReadonlyArray<MEETING_PREFERENCE>, readonly organizationName: string, readonly organizationIardCrd: string, readonly formAdvLink: string, readonly instantBook: boolean, readonly advisor: { readonly __typename?: 'AdvisorDraft', readonly id: string, readonly status: string }, readonly certifications: ReadonlyArray<{ readonly __typename?: 'Certification', readonly id: string, readonly name: string, readonly abbreviation: string }>, readonly idealClients: ReadonlyArray<{ readonly __typename?: 'IdealClient', readonly id: string, readonly name: string, readonly details: string }>, readonly profileImage: { readonly __typename?: 'ImageMultiPartOutput', readonly id: string, readonly url: string }, readonly organizationImage: { readonly __typename?: 'ImageMultiPartOutput', readonly id: string, readonly url: string }, readonly images: ReadonlyArray<{ readonly __typename?: 'ImageMultiPartOutput', readonly id: string, readonly url: string }>, readonly minimumInvestment: { readonly __typename?: 'MinimumInvestment', readonly id: string, readonly name: string, readonly value: number }, readonly feeTypes: ReadonlyArray<{ readonly __typename?: 'FeeTypeObjOutput', readonly feeType: FEE_TYPES }> } | null> };

export type AdvisorProfileByPublicIdQueryVariables = Exact<{
  publicProfileId: Scalars['String'];
}>;


export type AdvisorProfileByPublicIdQuery = { readonly __typename?: 'Query', readonly advisorProfileByPublicId: { readonly __typename?: 'AdvisorMarketplaceProfile', readonly publicId?: string | null, readonly id?: string | null, readonly advisorId?: string | null, readonly firstName: string, readonly lastName: string, readonly addressCity: string, readonly addressState: string, readonly advisorDescription: string, readonly businessDescription: string, readonly iardCrd: string, readonly calendarLink: string, readonly services: ReadonlyArray<SERVICES>, readonly registrationStates: ReadonlyArray<STATE_INITIAL>, readonly registration: REGISTRATION, readonly isPublished: boolean, readonly meetType: ReadonlyArray<MEETING_PREFERENCE>, readonly organizationName: string, readonly organizationIardCrd: string, readonly formAdvLink: string, readonly instantBook: boolean, readonly certifications: ReadonlyArray<{ readonly __typename?: 'Certification', readonly id: string, readonly name: string, readonly abbreviation: string }>, readonly idealClients: ReadonlyArray<{ readonly __typename?: 'IdealClient', readonly id: string, readonly name: string, readonly details: string }>, readonly profileImage: { readonly __typename?: 'ImageMultiPartOutput', readonly id: string, readonly url: string }, readonly organizationImage: { readonly __typename?: 'ImageMultiPartOutput', readonly id: string, readonly url: string }, readonly images: ReadonlyArray<{ readonly __typename?: 'ImageMultiPartOutput', readonly id: string, readonly url: string }>, readonly minimumInvestment: { readonly __typename?: 'MinimumInvestment', readonly id: string, readonly name: string, readonly value: number }, readonly feeTypes: ReadonlyArray<{ readonly __typename?: 'FeeTypeObjOutput', readonly feeType: FEE_TYPES }> } };


export const RequestMatchDocument = gql`
    mutation RequestMatch($input: MatchRequestInput!) {
  requestMatch(input: $input) {
    firstName
    lastName
    age
    usCodeState
    services
    income
    aum
    email
    optIn
    advisorProfileId
    timeframe
    instantBook
  }
}
    `;
export type RequestMatchMutationFn = Apollo.MutationFunction<RequestMatchMutation, RequestMatchMutationVariables>;

/**
 * __useRequestMatchMutation__
 *
 * To run a mutation, you first call `useRequestMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMatchMutation, { data, loading, error }] = useRequestMatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestMatchMutation(baseOptions?: Apollo.MutationHookOptions<RequestMatchMutation, RequestMatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestMatchMutation, RequestMatchMutationVariables>(RequestMatchDocument, options);
      }
export type RequestMatchMutationHookResult = ReturnType<typeof useRequestMatchMutation>;
export type RequestMatchMutationResult = Apollo.MutationResult<RequestMatchMutation>;
export type RequestMatchMutationOptions = Apollo.BaseMutationOptions<RequestMatchMutation, RequestMatchMutationVariables>;
export const getMatchConnectDocument = gql`
    query getMatchConnect($input: MatchConnectParams!) {
  getMatchConnect(input: $input) {
    firstName
    lastName
    age
    usCodeState
    services
    income
    aum
    email
    optIn
    advisorProfileId
    timeframe
    instantBook
  }
}
    `;

/**
 * __usegetMatchConnectQuery__
 *
 * To run a query within a React component, call `usegetMatchConnectQuery` and pass it any options that fit your needs.
 * When your component renders, `usegetMatchConnectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usegetMatchConnectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usegetMatchConnectQuery(baseOptions: Apollo.QueryHookOptions<getMatchConnectQuery, getMatchConnectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<getMatchConnectQuery, getMatchConnectQueryVariables>(getMatchConnectDocument, options);
      }
export function usegetMatchConnectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<getMatchConnectQuery, getMatchConnectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<getMatchConnectQuery, getMatchConnectQueryVariables>(getMatchConnectDocument, options);
        }
export type getMatchConnectQueryHookResult = ReturnType<typeof usegetMatchConnectQuery>;
export type getMatchConnectLazyQueryHookResult = ReturnType<typeof usegetMatchConnectLazyQuery>;
export type getMatchConnectQueryResult = Apollo.QueryResult<getMatchConnectQuery, getMatchConnectQueryVariables>;
export const PreviewAdvisorDocument = gql`
    query PreviewAdvisor($stoken: String!, $apiUrl: String!) {
  previewAdvisor(stoken: $stoken, apiUrl: $apiUrl) {
    publicId
    id
    advisorId
    firstName
    lastName
    addressCity
    addressState
    advisorDescription
    businessDescription
    advisor {
      id
      status
    }
    iardCrd
    calendarLink
    certifications {
      id
      name
      abbreviation
    }
    idealClients {
      id
      name
      details
    }
    services
    profileImage {
      id
      url
    }
    registrationStates
    registration
    organizationImage {
      id
      url
    }
    images {
      id
      url
    }
    isPublished
    meetType
    organizationName
    minimumInvestment {
      id
      name
      value
    }
    organizationIardCrd
    feeTypes {
      feeType
    }
    formAdvLink
    services
    instantBook
  }
}
    `;

/**
 * __usePreviewAdvisorQuery__
 *
 * To run a query within a React component, call `usePreviewAdvisorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewAdvisorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewAdvisorQuery({
 *   variables: {
 *      stoken: // value for 'stoken'
 *      apiUrl: // value for 'apiUrl'
 *   },
 * });
 */
export function usePreviewAdvisorQuery(baseOptions: Apollo.QueryHookOptions<PreviewAdvisorQuery, PreviewAdvisorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewAdvisorQuery, PreviewAdvisorQueryVariables>(PreviewAdvisorDocument, options);
      }
export function usePreviewAdvisorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewAdvisorQuery, PreviewAdvisorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewAdvisorQuery, PreviewAdvisorQueryVariables>(PreviewAdvisorDocument, options);
        }
export type PreviewAdvisorQueryHookResult = ReturnType<typeof usePreviewAdvisorQuery>;
export type PreviewAdvisorLazyQueryHookResult = ReturnType<typeof usePreviewAdvisorLazyQuery>;
export type PreviewAdvisorQueryResult = Apollo.QueryResult<PreviewAdvisorQuery, PreviewAdvisorQueryVariables>;
export const AdvisorProfileDocument = gql`
    query AdvisorProfile($id: String!) {
  advisorProfile(id: $id) {
    publicId
    id
    advisorId
    firstName
    lastName
    addressCity
    addressState
    advisorDescription
    businessDescription
    advisor {
      id
      status
    }
    iardCrd
    calendarLink
    certifications {
      id
      name
      abbreviation
    }
    idealClients {
      id
      name
      details
    }
    services
    profileImage {
      id
      url
    }
    registrationStates
    registration
    organizationImage {
      id
      url
    }
    images {
      id
      url
    }
    isPublished
    meetType
    organizationName
    minimumInvestment {
      id
      name
      value
    }
    organizationIardCrd
    feeTypes {
      feeType
    }
    formAdvLink
    services
    instantBook
  }
}
    `;

/**
 * __useAdvisorProfileQuery__
 *
 * To run a query within a React component, call `useAdvisorProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvisorProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvisorProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdvisorProfileQuery(baseOptions: Apollo.QueryHookOptions<AdvisorProfileQuery, AdvisorProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdvisorProfileQuery, AdvisorProfileQueryVariables>(AdvisorProfileDocument, options);
      }
export function useAdvisorProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdvisorProfileQuery, AdvisorProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdvisorProfileQuery, AdvisorProfileQueryVariables>(AdvisorProfileDocument, options);
        }
export type AdvisorProfileQueryHookResult = ReturnType<typeof useAdvisorProfileQuery>;
export type AdvisorProfileLazyQueryHookResult = ReturnType<typeof useAdvisorProfileLazyQuery>;
export type AdvisorProfileQueryResult = Apollo.QueryResult<AdvisorProfileQuery, AdvisorProfileQueryVariables>;
export const AdvisorSearchDocument = gql`
    mutation AdvisorSearch($input: AdvisorSearchInput) {
  advisorSearch(input: $input) {
    id
    publicId
    advisorId
    firstName
    lastName
    addressCity
    addressState
    advisorDescription
    businessDescription
    advisor {
      id
      status
    }
    iardCrd
    calendarLink
    certifications {
      id
      name
      abbreviation
    }
    idealClients {
      id
      name
      details
    }
    services
    profileImage {
      id
      url
    }
    registrationStates
    registration
    organizationImage {
      id
      url
    }
    images {
      id
      url
    }
    isPublished
    meetType
    organizationName
    minimumInvestment {
      id
      name
      value
    }
    organizationIardCrd
    feeTypes {
      feeType
    }
    formAdvLink
    services
    instantBook
  }
}
    `;
export type AdvisorSearchMutationFn = Apollo.MutationFunction<AdvisorSearchMutation, AdvisorSearchMutationVariables>;

/**
 * __useAdvisorSearchMutation__
 *
 * To run a mutation, you first call `useAdvisorSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvisorSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advisorSearchMutation, { data, loading, error }] = useAdvisorSearchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdvisorSearchMutation(baseOptions?: Apollo.MutationHookOptions<AdvisorSearchMutation, AdvisorSearchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdvisorSearchMutation, AdvisorSearchMutationVariables>(AdvisorSearchDocument, options);
      }
export type AdvisorSearchMutationHookResult = ReturnType<typeof useAdvisorSearchMutation>;
export type AdvisorSearchMutationResult = Apollo.MutationResult<AdvisorSearchMutation>;
export type AdvisorSearchMutationOptions = Apollo.BaseMutationOptions<AdvisorSearchMutation, AdvisorSearchMutationVariables>;
export const AdvisorProfileByPublicIdDocument = gql`
    query AdvisorProfileByPublicId($publicProfileId: String!) {
  advisorProfileByPublicId(publicProfileId: $publicProfileId) {
    publicId
    id
    advisorId
    firstName
    lastName
    addressCity
    addressState
    advisorDescription
    businessDescription
    iardCrd
    calendarLink
    certifications {
      id
      name
      abbreviation
    }
    idealClients {
      id
      name
      details
    }
    services
    profileImage {
      id
      url
    }
    registrationStates
    registration
    organizationImage {
      id
      url
    }
    images {
      id
      url
    }
    isPublished
    meetType
    organizationName
    minimumInvestment {
      id
      name
      value
    }
    organizationIardCrd
    feeTypes {
      feeType
    }
    formAdvLink
    services
    instantBook
  }
}
    `;

/**
 * __useAdvisorProfileByPublicIdQuery__
 *
 * To run a query within a React component, call `useAdvisorProfileByPublicIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvisorProfileByPublicIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvisorProfileByPublicIdQuery({
 *   variables: {
 *      publicProfileId: // value for 'publicProfileId'
 *   },
 * });
 */
export function useAdvisorProfileByPublicIdQuery(baseOptions: Apollo.QueryHookOptions<AdvisorProfileByPublicIdQuery, AdvisorProfileByPublicIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdvisorProfileByPublicIdQuery, AdvisorProfileByPublicIdQueryVariables>(AdvisorProfileByPublicIdDocument, options);
      }
export function useAdvisorProfileByPublicIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdvisorProfileByPublicIdQuery, AdvisorProfileByPublicIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdvisorProfileByPublicIdQuery, AdvisorProfileByPublicIdQueryVariables>(AdvisorProfileByPublicIdDocument, options);
        }
export type AdvisorProfileByPublicIdQueryHookResult = ReturnType<typeof useAdvisorProfileByPublicIdQuery>;
export type AdvisorProfileByPublicIdLazyQueryHookResult = ReturnType<typeof useAdvisorProfileByPublicIdLazyQuery>;
export type AdvisorProfileByPublicIdQueryResult = Apollo.QueryResult<AdvisorProfileByPublicIdQuery, AdvisorProfileByPublicIdQueryVariables>;

export const anAdvisorDraft = (overrides?: Partial<AdvisorDraft>): AdvisorDraft => {
    return {
        id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '2f1ebb74-60a1-456e-a216-393e7ee2563b',
        status: overrides && overrides.hasOwnProperty('status') ? overrides.status! : 'eligendi',
    };
};

export const anAdvisorMarketplaceDraft = (overrides?: Partial<AdvisorMarketplaceDraft>): AdvisorMarketplaceDraft => {
    return {
        addressCity: overrides && overrides.hasOwnProperty('addressCity') ? overrides.addressCity! : 'quia',
        addressState: overrides && overrides.hasOwnProperty('addressState') ? overrides.addressState! : 'necessitatibus',
        advisor: overrides && overrides.hasOwnProperty('advisor') ? overrides.advisor! : anAdvisorDraft(),
        advisorDescription: overrides && overrides.hasOwnProperty('advisorDescription') ? overrides.advisorDescription! : 'nisi',
        advisorId: overrides && overrides.hasOwnProperty('advisorId') ? overrides.advisorId! : 'fuga',
        businessDescription: overrides && overrides.hasOwnProperty('businessDescription') ? overrides.businessDescription! : 'cumque',
        calendarLink: overrides && overrides.hasOwnProperty('calendarLink') ? overrides.calendarLink! : 'reprehenderit',
        certifications: overrides && overrides.hasOwnProperty('certifications') ? overrides.certifications! : [aCertification()],
        feeTypes: overrides && overrides.hasOwnProperty('feeTypes') ? overrides.feeTypes! : [aFeeTypeObjOutput()],
        firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'aliquam',
        formAdvLink: overrides && overrides.hasOwnProperty('formAdvLink') ? overrides.formAdvLink! : 'est',
        iardCrd: overrides && overrides.hasOwnProperty('iardCrd') ? overrides.iardCrd! : 'reiciendis',
        id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'fdf17187-f6b1-4468-939a-d9e22a3b325c',
        idealClients: overrides && overrides.hasOwnProperty('idealClients') ? overrides.idealClients! : [anIdealClient()],
        images: overrides && overrides.hasOwnProperty('images') ? overrides.images! : [anImageMultiPartOutput()],
        instantBook: overrides && overrides.hasOwnProperty('instantBook') ? overrides.instantBook! : true,
        isPublished: overrides && overrides.hasOwnProperty('isPublished') ? overrides.isPublished! : true,
        lastName: overrides && overrides.hasOwnProperty('lastName') ? overrides.lastName! : 'est',
        meetType: overrides && overrides.hasOwnProperty('meetType') ? overrides.meetType! : [MEETING_PREFERENCE.IN_PERSON],
        minimumInvestment: overrides && overrides.hasOwnProperty('minimumInvestment') ? overrides.minimumInvestment! : aMinimumInvestment(),
        organizationIardCrd: overrides && overrides.hasOwnProperty('organizationIardCrd') ? overrides.organizationIardCrd! : 'voluptatibus',
        organizationImage: overrides && overrides.hasOwnProperty('organizationImage') ? overrides.organizationImage! : anImageMultiPartOutput(),
        organizationName: overrides && overrides.hasOwnProperty('organizationName') ? overrides.organizationName! : 'eius',
        profileImage: overrides && overrides.hasOwnProperty('profileImage') ? overrides.profileImage! : anImageMultiPartOutput(),
        publicId: overrides && overrides.hasOwnProperty('publicId') ? overrides.publicId! : '51a1adca-8e7f-491d-8478-2554fbbb2e2e',
        registration: overrides && overrides.hasOwnProperty('registration') ? overrides.registration! : REGISTRATION.SEC,
        registrationStates: overrides && overrides.hasOwnProperty('registrationStates') ? overrides.registrationStates! : [STATE_INITIAL.AK],
        services: overrides && overrides.hasOwnProperty('services') ? overrides.services! : [SERVICES.BUSINESS],
    };
};

export const anAdvisorMarketplaceProfile = (overrides?: Partial<AdvisorMarketplaceProfile>): AdvisorMarketplaceProfile => {
    return {
        addressCity: overrides && overrides.hasOwnProperty('addressCity') ? overrides.addressCity! : 'laboriosam',
        addressState: overrides && overrides.hasOwnProperty('addressState') ? overrides.addressState! : 'quos',
        advisor: overrides && overrides.hasOwnProperty('advisor') ? overrides.advisor! : anAdvisorDraft(),
        advisorDescription: overrides && overrides.hasOwnProperty('advisorDescription') ? overrides.advisorDescription! : 'laudantium',
        advisorId: overrides && overrides.hasOwnProperty('advisorId') ? overrides.advisorId! : 'aut',
        businessDescription: overrides && overrides.hasOwnProperty('businessDescription') ? overrides.businessDescription! : 'eveniet',
        calendarLink: overrides && overrides.hasOwnProperty('calendarLink') ? overrides.calendarLink! : 'et',
        certifications: overrides && overrides.hasOwnProperty('certifications') ? overrides.certifications! : [aCertification()],
        feeTypes: overrides && overrides.hasOwnProperty('feeTypes') ? overrides.feeTypes! : [aFeeTypeObjOutput()],
        firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'cupiditate',
        formAdvLink: overrides && overrides.hasOwnProperty('formAdvLink') ? overrides.formAdvLink! : 'id',
        iardCrd: overrides && overrides.hasOwnProperty('iardCrd') ? overrides.iardCrd! : 'repudiandae',
        id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'c140c23f-f40a-4ac5-891f-5d926e35e753',
        idealClients: overrides && overrides.hasOwnProperty('idealClients') ? overrides.idealClients! : [anIdealClient()],
        images: overrides && overrides.hasOwnProperty('images') ? overrides.images! : [anImageMultiPartOutput()],
        instantBook: overrides && overrides.hasOwnProperty('instantBook') ? overrides.instantBook! : true,
        isPublished: overrides && overrides.hasOwnProperty('isPublished') ? overrides.isPublished! : true,
        lastName: overrides && overrides.hasOwnProperty('lastName') ? overrides.lastName! : 'hic',
        meetType: overrides && overrides.hasOwnProperty('meetType') ? overrides.meetType! : [MEETING_PREFERENCE.IN_PERSON],
        minimumInvestment: overrides && overrides.hasOwnProperty('minimumInvestment') ? overrides.minimumInvestment! : aMinimumInvestment(),
        organizationIardCrd: overrides && overrides.hasOwnProperty('organizationIardCrd') ? overrides.organizationIardCrd! : 'placeat',
        organizationImage: overrides && overrides.hasOwnProperty('organizationImage') ? overrides.organizationImage! : anImageMultiPartOutput(),
        organizationName: overrides && overrides.hasOwnProperty('organizationName') ? overrides.organizationName! : 'dolorem',
        profileImage: overrides && overrides.hasOwnProperty('profileImage') ? overrides.profileImage! : anImageMultiPartOutput(),
        publicId: overrides && overrides.hasOwnProperty('publicId') ? overrides.publicId! : '206fd6f1-aa2d-4d62-9eab-12ba27c977f9',
        registration: overrides && overrides.hasOwnProperty('registration') ? overrides.registration! : REGISTRATION.SEC,
        registrationStates: overrides && overrides.hasOwnProperty('registrationStates') ? overrides.registrationStates! : [STATE_INITIAL.AK],
        services: overrides && overrides.hasOwnProperty('services') ? overrides.services! : [SERVICES.BUSINESS],
    };
};

export const anAdvisorMarketplacePublicProfile = (overrides?: Partial<AdvisorMarketplacePublicProfile>): AdvisorMarketplacePublicProfile => {
    return {
        addressCity: overrides && overrides.hasOwnProperty('addressCity') ? overrides.addressCity! : 'at',
        addressState: overrides && overrides.hasOwnProperty('addressState') ? overrides.addressState! : 'accusamus',
        advisorDescription: overrides && overrides.hasOwnProperty('advisorDescription') ? overrides.advisorDescription! : 'veniam',
        businessDescription: overrides && overrides.hasOwnProperty('businessDescription') ? overrides.businessDescription! : 'et',
        calendarLink: overrides && overrides.hasOwnProperty('calendarLink') ? overrides.calendarLink! : 'consectetur',
        certifications: overrides && overrides.hasOwnProperty('certifications') ? overrides.certifications! : [aCertification()],
        feeTypes: overrides && overrides.hasOwnProperty('feeTypes') ? overrides.feeTypes! : [aFeeTypeObjOutput()],
        firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'ut',
        formAdvLink: overrides && overrides.hasOwnProperty('formAdvLink') ? overrides.formAdvLink! : 'aperiam',
        iardCrd: overrides && overrides.hasOwnProperty('iardCrd') ? overrides.iardCrd! : 'perferendis',
        idealClients: overrides && overrides.hasOwnProperty('idealClients') ? overrides.idealClients! : [anIdealClient()],
        images: overrides && overrides.hasOwnProperty('images') ? overrides.images! : [anImageMultiPartOutput()],
        instantBook: overrides && overrides.hasOwnProperty('instantBook') ? overrides.instantBook! : true,
        isPublished: overrides && overrides.hasOwnProperty('isPublished') ? overrides.isPublished! : false,
        lastName: overrides && overrides.hasOwnProperty('lastName') ? overrides.lastName! : 'cumque',
        meetType: overrides && overrides.hasOwnProperty('meetType') ? overrides.meetType! : [MEETING_PREFERENCE.IN_PERSON],
        minimumInvestment: overrides && overrides.hasOwnProperty('minimumInvestment') ? overrides.minimumInvestment! : aMinimumInvestment(),
        organizationIardCrd: overrides && overrides.hasOwnProperty('organizationIardCrd') ? overrides.organizationIardCrd! : 'veniam',
        organizationImage: overrides && overrides.hasOwnProperty('organizationImage') ? overrides.organizationImage! : anImageMultiPartOutput(),
        organizationName: overrides && overrides.hasOwnProperty('organizationName') ? overrides.organizationName! : 'illo',
        profileImage: overrides && overrides.hasOwnProperty('profileImage') ? overrides.profileImage! : anImageMultiPartOutput(),
        publicId: overrides && overrides.hasOwnProperty('publicId') ? overrides.publicId! : '14b8c7d2-f917-4737-a6a0-de9c4a72277a',
        registration: overrides && overrides.hasOwnProperty('registration') ? overrides.registration! : REGISTRATION.SEC,
        registrationStates: overrides && overrides.hasOwnProperty('registrationStates') ? overrides.registrationStates! : [STATE_INITIAL.AK],
        services: overrides && overrides.hasOwnProperty('services') ? overrides.services! : [SERVICES.BUSINESS],
    };
};

export const anAdvisorSearchInput = (overrides?: Partial<AdvisorSearchInput>): AdvisorSearchInput => {
    return {
        age: overrides && overrides.hasOwnProperty('age') ? overrides.age! : 8982,
        aum: overrides && overrides.hasOwnProperty('aum') ? overrides.aum! : 'dolores',
        email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'architecto',
        income: overrides && overrides.hasOwnProperty('income') ? overrides.income! : 'et',
        services: overrides && overrides.hasOwnProperty('services') ? overrides.services! : [SERVICES.BUSINESS],
        state: overrides && overrides.hasOwnProperty('state') ? overrides.state! : 'quo',
        timeframe: overrides && overrides.hasOwnProperty('timeframe') ? overrides.timeframe! : 'vel',
    };
};

export const aCertification = (overrides?: Partial<Certification>): Certification => {
    return {
        abbreviation: overrides && overrides.hasOwnProperty('abbreviation') ? overrides.abbreviation! : 'cumque',
        id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '3796b072-7dc5-42c8-b791-617adaa83efd',
        name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'aut',
    };
};

export const aFeeTypeObjOutput = (overrides?: Partial<FeeTypeObjOutput>): FeeTypeObjOutput => {
    return {
        feeType: overrides && overrides.hasOwnProperty('feeType') ? overrides.feeType! : FEE_TYPES.ASSET,
    };
};

export const anIdealClient = (overrides?: Partial<IdealClient>): IdealClient => {
    return {
        details: overrides && overrides.hasOwnProperty('details') ? overrides.details! : 'doloribus',
        id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '0fd707e9-5cc8-49de-af92-fe6661c52d89',
        name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'soluta',
    };
};

export const anImageMultiPartOutput = (overrides?: Partial<ImageMultiPartOutput>): ImageMultiPartOutput => {
    return {
        id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : 'dolor',
        url: overrides && overrides.hasOwnProperty('url') ? overrides.url! : 'aut',
    };
};

export const aMatchConnectParams = (overrides?: Partial<MatchConnectParams>): MatchConnectParams => {
    return {
        advisorProfileId: overrides && overrides.hasOwnProperty('advisorProfileId') ? overrides.advisorProfileId! : '83409961-65b7-4c6e-8bf0-0d0a0c921de5',
        email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'non',
    };
};

export const aMatchRequestDto = (overrides?: Partial<MatchRequestDto>): MatchRequestDto => {
    return {
        advisorProfileId: overrides && overrides.hasOwnProperty('advisorProfileId') ? overrides.advisorProfileId! : '486a3e3d-d705-43ba-b70f-f5e19d6e8ef6',
        age: overrides && overrides.hasOwnProperty('age') ? overrides.age! : 9893,
        aum: overrides && overrides.hasOwnProperty('aum') ? overrides.aum! : '2ef7402b-8a65-4ec9-96e2-bc0728f8b3f6',
        email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'quas',
        firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'aliquam',
        goals: overrides && overrides.hasOwnProperty('goals') ? overrides.goals! : [GOALS.DEBT],
        goalsNote: overrides && overrides.hasOwnProperty('goalsNote') ? overrides.goalsNote! : 'rerum',
        income: overrides && overrides.hasOwnProperty('income') ? overrides.income! : '091327e2-4b07-409e-b765-2ae59202e6d5',
        instantBook: overrides && overrides.hasOwnProperty('instantBook') ? overrides.instantBook! : false,
        lastName: overrides && overrides.hasOwnProperty('lastName') ? overrides.lastName! : 'deleniti',
        message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'et',
        optIn: overrides && overrides.hasOwnProperty('optIn') ? overrides.optIn! : true,
        services: overrides && overrides.hasOwnProperty('services') ? overrides.services! : ['dicta'],
        timeframe: overrides && overrides.hasOwnProperty('timeframe') ? overrides.timeframe! : 'labore',
        usCodeState: overrides && overrides.hasOwnProperty('usCodeState') ? overrides.usCodeState! : 'unde',
        workIndustry: overrides && overrides.hasOwnProperty('workIndustry') ? overrides.workIndustry! : INDUSTRIES.AGRICULTURE,
        workIndustryNote: overrides && overrides.hasOwnProperty('workIndustryNote') ? overrides.workIndustryNote! : 'voluptatibus',
    };
};

export const aMatchRequestInput = (overrides?: Partial<MatchRequestInput>): MatchRequestInput => {
    return {
        advisorProfileId: overrides && overrides.hasOwnProperty('advisorProfileId') ? overrides.advisorProfileId! : '4dcc719e-9586-47ab-9283-388c8e9b5a05',
        age: overrides && overrides.hasOwnProperty('age') ? overrides.age! : 5781,
        aum: overrides && overrides.hasOwnProperty('aum') ? overrides.aum! : '9ae6e7e6-8a4d-418b-a516-adc1ebf9179b',
        email: overrides && overrides.hasOwnProperty('email') ? overrides.email! : 'dolorem',
        firstName: overrides && overrides.hasOwnProperty('firstName') ? overrides.firstName! : 'temporibus',
        goals: overrides && overrides.hasOwnProperty('goals') ? overrides.goals! : [GOALS.DEBT],
        goalsNote: overrides && overrides.hasOwnProperty('goalsNote') ? overrides.goalsNote! : 'voluptatem',
        income: overrides && overrides.hasOwnProperty('income') ? overrides.income! : '7d1d9322-80e3-475f-adcc-395efb477108',
        instantBook: overrides && overrides.hasOwnProperty('instantBook') ? overrides.instantBook! : true,
        lastName: overrides && overrides.hasOwnProperty('lastName') ? overrides.lastName! : 'labore',
        message: overrides && overrides.hasOwnProperty('message') ? overrides.message! : 'repudiandae',
        optIn: overrides && overrides.hasOwnProperty('optIn') ? overrides.optIn! : false,
        services: overrides && overrides.hasOwnProperty('services') ? overrides.services! : ['tenetur'],
        timeframe: overrides && overrides.hasOwnProperty('timeframe') ? overrides.timeframe! : 'et',
        usCodeState: overrides && overrides.hasOwnProperty('usCodeState') ? overrides.usCodeState! : 'inventore',
        workIndustry: overrides && overrides.hasOwnProperty('workIndustry') ? overrides.workIndustry! : INDUSTRIES.AGRICULTURE,
        workIndustryNote: overrides && overrides.hasOwnProperty('workIndustryNote') ? overrides.workIndustryNote! : 'illum',
    };
};

export const aMetaData = (overrides?: Partial<MetaData>): MetaData => {
    return {
        certifications: overrides && overrides.hasOwnProperty('certifications') ? overrides.certifications! : [aCertification()],
        idealClients: overrides && overrides.hasOwnProperty('idealClients') ? overrides.idealClients! : [anIdealClient()],
        minimumInvestments: overrides && overrides.hasOwnProperty('minimumInvestments') ? overrides.minimumInvestments! : aMinimumInvestment(),
    };
};

export const aMinimumInvestment = (overrides?: Partial<MinimumInvestment>): MinimumInvestment => {
    return {
        id: overrides && overrides.hasOwnProperty('id') ? overrides.id! : '21a0d4da-5dc4-40b3-b417-816e08919f43',
        name: overrides && overrides.hasOwnProperty('name') ? overrides.name! : 'saepe',
        value: overrides && overrides.hasOwnProperty('value') ? overrides.value! : 3114,
    };
};

export const aMutation = (overrides?: Partial<Mutation>): Mutation => {
    return {
        acceptMatchRequest: overrides && overrides.hasOwnProperty('acceptMatchRequest') ? overrides.acceptMatchRequest! : false,
        advisorSearch: overrides && overrides.hasOwnProperty('advisorSearch') ? overrides.advisorSearch! : [anAdvisorMarketplaceProfile()],
        declineMatchRequest: overrides && overrides.hasOwnProperty('declineMatchRequest') ? overrides.declineMatchRequest! : false,
        requestMatch: overrides && overrides.hasOwnProperty('requestMatch') ? overrides.requestMatch! : aMatchRequestDto(),
    };
};

export const aQuery = (overrides?: Partial<Query>): Query => {
    return {
        advisorProfile: overrides && overrides.hasOwnProperty('advisorProfile') ? overrides.advisorProfile! : anAdvisorMarketplaceProfile(),
        advisorProfileByPublicId: overrides && overrides.hasOwnProperty('advisorProfileByPublicId') ? overrides.advisorProfileByPublicId! : anAdvisorMarketplaceProfile(),
        getMatchConnect: overrides && overrides.hasOwnProperty('getMatchConnect') ? overrides.getMatchConnect! : aMatchRequestDto(),
        metaData: overrides && overrides.hasOwnProperty('metaData') ? overrides.metaData! : aMetaData(),
        previewAdvisor: overrides && overrides.hasOwnProperty('previewAdvisor') ? overrides.previewAdvisor! : anAdvisorMarketplaceDraft(),
    };
};
