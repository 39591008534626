import { useRef } from 'react';
import { FC, ReactNode, useState } from 'react';
import { themeColors } from 'src/core/theme/Theme';
import { useOnClickOutside } from 'src/hooks/useOnOutsideClick';
import * as Styled from './TooltipStyled';

export interface TooltipProps {
  delay?: number;
  position?: 'top' | 'bottom' | 'left' | 'right';
  content?: string | ReactNode;
  color?: keyof typeof themeColors;
  triggerBy?: 'click' | 'hover';
  dataTestId?: string;
}

let timeout: NodeJS.Timeout;

export const Tooltip: FC<TooltipProps> = ({
  delay = 200,
  children,
  position = 'bottom',
  content = '',
  color = 'Dark Greige',
  triggerBy = 'click',
  dataTestId = 'Tooltip',
}) => {
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };
  const ref = useRef(null);
  useOnClickOutside(ref, hideTip);

  const triggerByProps =
    triggerBy === 'click'
      ? {
          onClick: showTip,
        }
      : {
          onMouseEnter: showTip,
          onMouseLeave: hideTip,
        };
  return (
    <Styled.TooltipWrapper {...triggerByProps}>
      {children}
      {active && (
        <Styled.Tooltip data-testid={dataTestId} ref={ref} $color={color} $position={position}>
          {content}
        </Styled.Tooltip>
      )}
    </Styled.TooltipWrapper>
  );
};
