import { FC } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import * as Styled from './ToastStyled';

export const Toaster: FC = () => (
  <Styled.ToasterContainer
    hideProgressBar
    closeOnClick={false}
    draggable={false}
    position='bottom-center'
  />
);
