import { ApolloError } from '@apollo/client';
import { FC } from 'react';
import { CenterOfPage } from 'src/atoms';
import { FadeIn } from 'src/atoms/FadeIn/FadeIn';
import { LoadingIcon } from 'src/atoms/Icons/Icons';
import { Footer } from 'src/molecules';
import { NotFound } from 'src/pages/notFound/NotFound';

import * as Styled from './PageStyled';

interface Props {
  loading?: boolean;
  error?: boolean | ApolloError;
  hideFooter?: boolean;
  className?: string;
}
export const Page: FC<Props> = ({ loading, error, children, hideFooter, className }) => {
  if (error) {
    return <NotFound hideHeader message='Something went wrong' />;
  }
  return (
    <>
      {loading ? (
        <CenterOfPage>
          <FadeIn>
            <LoadingIcon />
          </FadeIn>
        </CenterOfPage>
      ) : (
        <FadeIn>
          <Styled.PageContainer className={className} id='match-page'>
            {children}
            {!hideFooter && <Footer />}
          </Styled.PageContainer>
        </FadeIn>
      )}
    </>
  );
};
