import { FC } from 'react';

import { Checkbox } from 'src/atoms/Checkbox/Checkbox';

import { Title, varStyles } from '../CommonStyled';

import * as Styled from './PillStyled';

export const testId = 'selectable-pill';

interface PillProps {
  isSelected: boolean;
  onChange: (checked: boolean) => void;
  title: string;
  isCheckbox?: boolean;
}

export const Pill: FC<PillProps> = ({ isCheckbox = false, isSelected, onChange, title }) => {
  const Input = !isCheckbox ? Styled.Radio : Checkbox;

  const style = !isSelected ? {} : varStyles;

  return (
    <Styled.Container data-testid={testId} style={style}>
      <Title>{title}</Title>
      <Input checked={isSelected} onChange={(e) => onChange(e.target.checked)} />
    </Styled.Container>
  );
};
