import styled from 'styled-components';

import { AltruistLogo } from 'src/atoms';
import { CloseIcon } from 'src/atoms/Icons/Icons';
import { themeColors } from 'src/core/theme/Theme';
import { rem } from 'src/core/theme/utilities';
import { flexCenter } from 'src/styles/layout';
import { mediaQuery } from 'src/styles/mediaQuery';

export const StyledCloseIcon = styled(CloseIcon)`
  padding-right: ${rem(20)};
  height: ${rem(18)};
  width: ${rem(18)};
  path {
    fill: ${themeColors['Black']};
  }
`;

export const LogoContainer = styled('div')`
  ${flexCenter}

  justify-content: space-between;
  height: ${rem(120)};

  > svg {
    height: ${rem(45)};
  }

  ${mediaQuery(undefined, 'medium')} {
    height: ${rem(80)};

    > svg {
      height: ${rem(40)};
    }
  }

  button {
    margin-right: ${rem(30)};
  }
`;

export const HomeLogo = styled(AltruistLogo)`
  padding-left: ${rem(32)};
  &:hover {
    cursor: pointer;
  }
`;
