import { Component, ErrorInfo, ReactNode } from 'react';
import { NotFound } from 'src/pages/notFound/NotFound';
import { sendErrorContext } from 'src/utils/monitoring/datadog';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    sendErrorContext({ error, context: { errorInfo } });
  }

  public render() {
    if (this.state.hasError) {
      return <NotFound message='Something went wrong' />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
