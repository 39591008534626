import React, { FC } from 'react';
import * as Styled from './InputStyled';
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  ref?: React.Ref<HTMLInputElement>;
  error?: string;
}

export const Input: FC<InputProps> = React.forwardRef((props, ref) => {
  return (
    <>
      <Styled.InputContainer data-testid='Input-wrapper' {...props} ref={ref} />
      {!!props.error?.length && (
        <Styled.ErrorMessage data-testid='Input-error' size='CAPTION'>
          {props.error}
        </Styled.ErrorMessage>
      )}
    </>
  );
});
