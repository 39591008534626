import styled from 'styled-components';

import { theme } from 'src/core/theme/Theme';

import { BodyProps } from './sharedTypes';

export const BodyStyles = ({ size = 'LARGE', color }: BodyProps) => {
  let textStyle: keyof typeof theme.textStyles = 'Body/LRG';
  if (size === 'SMALL') {
    textStyle = 'Body/SML';
  }
  if (size === 'CAPTION') {
    textStyle = 'Body/Caption';
  }
  if (size === 'DEFAULT') {
    textStyle = 'Body/Default';
  }
  return `
  font-family: ${theme.textStyles[textStyle].font};
  font-size: ${theme.textStyles[textStyle].fontSize};
  font-weight: ${theme.textStyles[textStyle].fontWeight};
  line-height: ${theme.textStyles[textStyle].lineHeight};
  letter-spacing: ${theme.textStyles[textStyle].letterSpacing};
  color: ${color || theme.textStyles[textStyle].color};
`;
};
export const Body = styled.div<BodyProps>`
  ${BodyStyles}
`;
