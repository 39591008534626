import { ApolloError } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { usePersistedState } from 'src/hooks/usePersistedState';
import { LocalStorageKeys } from 'src/utils/helpers/localStorageHelpers';

interface ErrorContext {
  title?: string;
  error?: boolean | ApolloError | Error;
  context?: Record<string, unknown>;
}

const sessionMetaData = {
  path: window.location.pathname,
};
// TODO: May need to manually send error
export const sendErrorContext = ({ error, context }: ErrorContext) => {
  datadogRum.addError(error, {
    sessionMetaData,
    ...context,
  });
};

export const useSendErrorContext = () => {
  const [values] = usePersistedState(LocalStorageKeys.CONSULTATION_FORM);
  const handleSend = ({ title = 'Error', error, context = {} }: ErrorContext) => {
    sendErrorContext({
      title,
      error,
      context: { ...context, user: values },
    });
  };
  return handleSend;
};
