import styled, { css } from 'styled-components';
import { themeColors, zIndex } from 'src/core/theme/Theme';
import { rem } from 'src/core/theme/utilities';

// TODO: fix css for other positions
interface TooltipProps {
  $size?: 'SMALL' | 'LARGE';
  $position?: 'top' | 'bottom' | 'left' | 'right';
  $color?: keyof typeof themeColors;
}

export const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const Tooltip = styled.div<TooltipProps>`
  position: absolute;
  left: 43%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: ${zIndex.max};
  padding: 24px 24px 24px;
  border-radius: 2px;
  font-size: 14px;
  color: ${themeColors['White']};
  background: ${themeColors['Black']};
  width: ${({ $size }) => {
    let value = 256;
    if ($size === 'LARGE') {
      value = 336;
    }
    return `${rem(value)}`;
  }};
  ${({ $position = 'bottom' }) => {
    if ($position === 'top') {
      return css`
        top: ${rem(-100)};
      `;
    }
    if ($position === 'bottom') {
      return css`
        bottom: ${rem(-205)};
      `;
    }
    if ($position === 'left') {
      return css`
        left: auto;
        right: calc(100% + ${rem(10)});
        top: 85%;
        transform: translateX(0) translateY(-50%);
      `;
    }
    if ($position === 'right') {
      return css`
        left: calc(100% + ${rem(10)});
        top: 5%;
        transform: translateX(0) translateY(-50%);
      `;
    }
  }}
`;
