import styled from 'styled-components';

import { rem } from 'src/core/theme/utilities';
import { flexCenter } from 'src/styles/layout';

import { containerStyles } from '../CommonStyled';

export const Container = styled('label')`
  ${containerStyles}

  border-radius: ${rem(8)};
  flex-direction: column;
  padding: ${rem(24)};
  width: auto;
`;

export const TopRow = styled('div')`
  ${flexCenter}

  flex: 1;
  min-height: ${rem(30)};
  justify-content: space-between;
`;

export const BottomRow = styled('div')`
  flex: 1;
  margin-top: ${rem(30)};
  width: 100%;
`;
