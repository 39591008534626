import { CSSProperties } from 'react';
import styled from 'styled-components';

import { flexCenter } from 'src/styles/layout';
import { rem } from 'src/core/theme/utilities';
import { mediaQuery } from 'src/styles/mediaQuery';
import { Container as CardContainer } from 'src/atoms/Selectable/Card/CardStyled';
import { Container as PillContainer } from 'src/atoms/Selectable/Pill/PillStyled';

export const isColumnStyles = {
  '--flex-direction': 'column',
  '--margin-top': rem(16),
  '--margin': 0,
} as CSSProperties;

export const Container = styled('div')`
  ${flexCenter}

  height: auto;
  margin-bottom: ${rem(40)};

  ${mediaQuery('small')} {
    flex-direction: var(--flex-direction, row);
    flex-wrap: wrap;

    ${CardContainer} {
      margin: var(--margin, ${rem(8)});
    }

    ${CardContainer} + ${CardContainer} {
      margin-top: var(--margin-top, var(--margin, ${rem(8)}));
    }
  }

  ${mediaQuery(undefined, 'small')} {
    flex-direction: column;

    ${CardContainer} + ${CardContainer} {
      margin-top: ${rem(16)};
    }
  }

  ${PillContainer} + ${PillContainer} {
    margin-top: ${rem(16)};
  }
`;
