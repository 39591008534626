import styled from 'styled-components';

import { ThemeColors } from 'src/core/theme/Theme';
import { rem } from 'src/core/theme/utilities';

export interface DividerProps {
  marginTop?: number;
  marginBottom?: number;
  width?: string;
  color?: ThemeColors;
  my?: number;
  mx?: number;
}

export const DividerLine = styled.div<DividerProps>`
  width: ${({ width }) => width || '100%'};
  margin-top: ${({ marginTop = 60 }) => rem(marginTop)};
  margin-bottom: ${({ marginBottom = 0 }) => rem(marginBottom)};
  height: ${rem(1)};
  background: ${({ theme, color = 'Dark Greige' }) => theme.colors[color]};
  ${({ mx = 0, my = 0 }) => {
    if (mx) {
      return `
      margin-left: ${rem(mx)};
      margin-right: ${rem(mx)};
      `;
    }
    if (my) {
      return `
      margin-top: ${rem(my)};
      margin-bottom: ${rem(my)};

      `;
    }
  }}
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Divider = (props: DividerProps) => {
  return (
    <Container data-testid='Divider-wrapper'>
      <DividerLine data-testid='Divider' {...props} />
    </Container>
  );
};
