import { Body } from 'src/atoms/Typography';
import { themeColors } from 'src/core/theme/Theme';
import { rem } from 'src/core/theme/utilities';
import styled from 'styled-components';

export const Container = styled.div<{ isCloseable?: boolean }>`
  background-color: ${themeColors['Green light']};
  color: ${themeColors['Dark Green']};
  bottom: ${({ theme: { isMobile } }) => (isMobile ? rem(104) : 0)};
  padding: ${rem(16)};
  position: sticky;
  justify-content: center;
  display: flex;

  ${({ isCloseable }) =>
    isCloseable &&
    `justify-content: space-between;
  align-items: center;`};
`;

export const BannerText = styled(Body)`
  color: ${themeColors['Dark Green']};
`;

export const InfoIconContainer = styled.div`
  padding: ${rem(6)} ${rem(16)} ${rem(2)} ${rem(16)};
`;
