import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

type Env = 'local' | 'dev' | 'test' | 'stage' | 'prod';
interface ApiHost {
  host: string;
  env: Env;
  splitApiKey: string;
  amplitudeApiKey: string;
}

export const getApiHost = (): ApiHost => {
  let host = 'http://localhost:4001/graphql';
  let env: Env = 'local';
  let splitApiKey = 'eqkbtc9ma272isnu67lsnbcp7nhju90dee70';
  const { origin } = window.location;

  let amplitudeApiKey = '809d3f3f255f1e2c71fe1a5b273089e5';

  switch (true) {
    case origin.includes('dev'):
      env = 'dev';
      host = 'https://api.dev1.altruistnet.tech/match/graphql';
      break;
    case origin.includes('test'):
      env = 'test';
      host = 'https://api.test1.altruistnet.tech/match/graphql';
      splitApiKey = '9modgk09qer1gga03jh81eevhccap6uc9ke6';
      break;
    case origin.includes('stage'):
      env = 'stage';
      host = 'https://api.stage1.altruistnet.tech/match/graphql';
      splitApiKey = 'gj2n6c1h04r44nf89j8bb3i093pnrtirbnrq';
      break;
    case origin.includes('altruist.com'):
      env = 'prod';
      host = 'https://api.altruist.com/match/graphql';
      splitApiKey = 'n2soknb1prahnc4nal0bmcde8t9k0en0unu0';
      amplitudeApiKey = '5045c902d76d849196a3d2a287d8a813';
      break;
  }

  return {
    amplitudeApiKey,
    env,
    host,
    splitApiKey,
  };
};
export type UserAgents = 'safari' | 'chrome' | 'firefox' | 'opera';

const detectBrowser = () => {
  const userAgent = navigator.userAgent;
  let browserName: UserAgents = 'safari';
  if (userAgent.includes('CriOS')) {
    browserName = 'chrome';
  }

  return browserName;
};
export const environment = getApiHost().env;
export const userAgent = detectBrowser();

export const { splitApiKey } = getApiHost();

const uploadLink = createUploadLink({
  uri: getApiHost().host,
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: uploadLink,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  credentials: 'include',
});

export default client;
