// NOTE: add all local storage keys here
export enum LocalStorageKeys {
  TODO = 'TODO',
  REQUESTED_ADVISORS = 'REQUESTED_ADVISORS',
  CONSULTATION_FORM = 'CONSULTATION_FORM',
  QuizStep = 'QuizStep',
}
export type LocatStorageValue = Record<string, unknown> | string | number | null | undefined | [];
export const addItemToLocalStorage = (storageKey: LocalStorageKeys, value: LocatStorageValue) => {
  localStorage.setItem(storageKey, JSON.stringify(value));
};
export const removeItemFromLocalStorage = (key: LocalStorageKeys) => {
  localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  Object.keys(LocalStorageKeys).forEach((key) => {
    localStorage.removeItem(key);
  });
};
