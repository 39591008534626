import { rem } from 'src/core/theme/utilities';
import styled from 'styled-components';

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${rem(10)};
`;
export const Option = styled.div`
  margin-left: 10px;
`;
