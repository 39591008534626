import styled from 'styled-components';
import { CloseIcon } from 'src/atoms/Icons/Icons';

import { themeColors } from 'src/core/theme/Theme';
import { rem } from 'src/core/theme/utilities';
import { CircleIcon } from 'src/molecules';

export const LightBoxContainer = styled.div`
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: ${themeColors['Black']};
  width: 100%;
`;

export const LightBoxImageContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LightBoxImage = styled.img`
  width: 100%;
  max-height: ${rem(565)};
  object-fit: contain;
`;
export const LightBoxClose = styled.div`
  position: absolute;
  top: 5%;
  left: 5%;
`;
export const LightBoxCloseIcon = styled(CloseIcon)``;

export const LightBoxImageIndex = styled.div`
  font-size: ${rem(14)};
  margin-top: ${rem(600)};
  color: ${themeColors['White']};
  bottom: ${rem(100)};
  left: 50%;
  text-align: center;
  position: absolute;
  z-index: 1;
`;

export const NavigationButtonLeft = styled(CircleIcon).attrs(() => ({
  backgroundColor: themeColors['80Black'],
}))`
  position: absolute;
  left: ${rem(100)};
  z-index: 1;
`;
export const NavigationButtonRight = styled(CircleIcon).attrs(() => ({
  backgroundColor: themeColors['80Black'],
}))`
  position: absolute;
  right: ${rem(100)};
  z-index: 1;
`;

export const TriggerContainer = styled.div`
  display: contents;
`;
