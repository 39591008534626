import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { ScreenClassProvider, setConfiguration } from 'react-grid-system';

import ApolloClient, { splitApiKey } from 'src/apollo-client';

import { AuthProvider } from './core/AuthProvider';
import { GlobalStyle } from './styles/global';
import Theme, { breakpoints } from './core/theme/Theme';
import { initializeAnalytics } from './utils/analytics';
import { Toaster } from './atoms';
import { monitorInit } from './utils/monitoring/datadog/dataDogInit';
import ErrorBoundary from './core/ErrorBoundary';
import { FeatureProvider } from './core/FeatureProvider';

setConfiguration({
  gridColumns: 16,
  breakpoints: [breakpoints.xs, breakpoints.sm, breakpoints.md, breakpoints.lg, breakpoints.xl],
});

const AuthRoutes = lazy(() => import('./core/AuthRoutes'));
const sdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: splitApiKey,
    key: 'key',
  },
};

const setHeightUnit = () => {
  document.body.style.setProperty('--vh', window.innerHeight / 100 + 'px');
};

function App() {
  const redirectToHome = () => {
    if (!window.location.pathname.includes('match')) {
      window.location.pathname = '/match';
    }
  };

  useEffect(() => {
    monitorInit();
    initializeAnalytics();
    redirectToHome();

    // this should handle iOS safari's height resize when browser navbars show
    setHeightUnit();
    window.addEventListener('resize', setHeightUnit);
  }, []);

  return (
    <SplitFactory config={sdkConfig}>
      <FeatureProvider>
        <AuthProvider>
          <ApolloProvider client={ApolloClient}>
            <ScreenClassProvider>
              <GlobalStyle />
              <BrowserRouter basename='/match'>
                <Suspense fallback={<></>}>
                  <ErrorBoundary>
                    <AuthRoutes />
                  </ErrorBoundary>
                </Suspense>
                <Theme>
                  <Toaster />
                </Theme>
              </BrowserRouter>
            </ScreenClassProvider>
          </ApolloProvider>
        </AuthProvider>
      </FeatureProvider>
    </SplitFactory>
  );
}

export default App;
