import { css } from 'styled-components';

import { rem } from 'src/core/theme/utilities';

export const commonInput = css`
  background-color: ${(props) => props.theme.colors['Off white']};
  border: 1px solid transparent;
  border-radius: ${rem(2)};
  box-sizing: border-box;
  font-family: ${({ theme }) => theme['font-family-aktiv-grotesk']};
  font-size: ${rem(16)};
  outline: none;
  padding: ${rem(24)};
  width: 100%;

  :focus {
    border: 1px solid ${(props) => props.theme.colors['Dark Greige']};
  }

  &[aria-invalid='true'] {
    border: 1px solid ${(props) => props.theme.colors['Red']};
  }
`;
