import { init } from '@amplitude/analytics-browser';

import { getApiHost } from 'src/apollo-client';

export const analyticsKey = 'ANALYTICS_ON';

export const initializeAnalytics = async () => {
  const key = getApiHost().amplitudeApiKey;
  localStorage.removeItem(analyticsKey);

  if (key) {
    try {
      await init(key).promise;
      localStorage.setItem(analyticsKey, 'true');
    } catch (e) {
      console.error(e);
    }
  }
};
