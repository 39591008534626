import { ReactNode } from 'react';
import { useScreenClass } from 'react-grid-system';
import styled, { ThemeProvider } from 'styled-components';

import figmaData from './figma/theme.json';
import { easing, rem } from './utilities';

export const breakpoints = {
  xs: 500,
  sm: 768,
  md: 1048,
  lg: 1200,
  xl: 1500,
};

export const zIndex = {
  behind: -1,
  min: 1,
  mid: 50,
  max: 100,
};

export const bottomBarMobileHeight = rem(102);

export const gridSettings = {
  space: [0, 4, 8, 12, 16],
  breakpoints: [
    `${breakpoints.xs}`,
    `${breakpoints.sm}`,
    `${breakpoints.md}`,
    `${breakpoints.lg}`,
    `${breakpoints.xl}`,
  ],
};
export enum ThemeVariant {
  LIGHT = 'light',
  DARK = 'dark',
  GREEN = 'green',
  GRAY = 'gray',
  LIGHTGRAY = 'lightGray',
  LIGHTBROWN = 'lightBrown',
  LIGHTCREAM = 'lightCream',
  OFFWHITE = 'offwhite',
}

export type ThemeColor =
  | ThemeVariant.LIGHT
  | ThemeVariant.DARK
  | ThemeVariant.GREEN
  | ThemeVariant.GRAY
  | ThemeVariant.LIGHTGRAY
  | ThemeVariant.LIGHTBROWN
  | ThemeVariant.LIGHTCREAM
  | ThemeVariant.OFFWHITE;

// theme variables
// ---
const framework = {
  'content-max-width-container': 1026,
  'content-max-width-fluid': 1920,
  'border-radius': `${rem(2)}`,
  // form-field-spacing is 48px with design system
  'form-field-spacing': '30px',
  'form-field-font-size': '15px',
  'form-field-static-background': figmaData.colors['White'],
  'form-field-focus-background': figmaData.colors['Dark Gray'],
  'form-field-hover-border': figmaData.colors['Light Gray'],
  'form-field-focus-border': figmaData.colors['Light Gray'],
  'form-field-static-border-error': figmaData.colors['Red'],
  'form-field-radius': '2px',
  'form-field-padding-x': `${rem(28)}`,
  'form-field-padding-y': `${rem(28)}`,
  'form-button-static-background': figmaData.colors['Green'],
  'form-button-hover-background': figmaData.colors['Green'],
  'form-button-background': figmaData.colors['Green'],
  'form-button-padding': `${rem(20)} ${rem(40)}`,
  'form-button-color': figmaData.colors['White'],
  'font-family-begum': `${figmaData.fonts.find((x) => x === 'Begum')}, serif`,
  'font-family-aktiv-grotesk': `${figmaData.fonts.find((x) => x === 'Aktiv Grotesk')}`,
  'font-family-ivar-headline-regular': `${figmaData.fonts.find(
    (x) => x === 'Ivar Headline Regular',
  )}, serif`,
  'font-family-ivar-headline-medium': `${figmaData.fonts.find(
    (x) => x === 'Ivar Headline Medium',
  )}, serif`,
  'theme-background-transition': `background 3s ${easing['standard']}`,
};

/** The full collection of colors provided by the theme. */
export const themeColors = figmaData.colors;

export type ThemeColors = keyof typeof themeColors;

// merge Figma and custom framework variables to create the global theme
export const theme = {
  name: 'Altruist Theme',
  active: {
    color: figmaData.colors['Black'],
    'background-color': figmaData.colors['Black'],
  },
  color: ThemeVariant.LIGHT,
  ...figmaData, // vars from Figma API
  ...gridSettings, // rebase grid settings
  ...framework,
};

// theme style
// ---

// global theme styles
const BaseDiv = styled.div`
  font-family: ${(props) => props.theme['font-family-aktiv-grotesk']};
  transition: ${(props) => props.theme['theme-background-transition']};

  &.is-loading {
    background-color: ${(props) => props.theme.colors['White']};
  }
`;

// theme color varients
const StyledDark = styled(BaseDiv)`
  color: ${(props) => props.theme.colors['Light Gray']};
  background-color: ${(props) => props.theme.colors['Black']};
`;

const StyledLight = styled(BaseDiv)`
  color: ${(props) => props.theme.colors['Black']};
  background-color: ${(props) => props.theme.colors['White']};
`;

const StyledGray = styled(BaseDiv)`
  color: ${(props) => props.theme.colors['Black']};
  background-color: ${(props) => props.theme.colors['Light Gray']};
`;

const StyledGreen = styled(BaseDiv)`
  color: ${(props) => props.theme.colors['White']};
  background-color: ${(props) => props.theme.colors['Green']};
`;

const StyledCream = styled(BaseDiv)`
  color: ${(props) => props.theme.colors['Dark Greige']};
  background-color: ${(props) => props.theme.colors['Cream']};
`;

const StyledLightCream = styled(BaseDiv)`
  color: ${(props) => props.theme.colors['Dark Greige']};
  background-color: ${(props) => props.theme.colors['Light Cream']};
`;

const StyledOffWhite = styled(BaseDiv)`
  color: ${(props) => props.theme.colors['Dark Greige']};
  background-color: ${(props) => props.theme.colors['Off white']};
`;

// generate color theme
const renderThemeColor = (props: ThemeProps) => {
  switch (props.color) {
    case ThemeVariant.DARK:
      theme.active.color = theme.colors['Light Gray'];
      theme.active['background-color'] = theme.colors['Black'];
      return <StyledDark {...props}>{props.children}</StyledDark>;

    case ThemeVariant.GREEN:
      theme.active.color = theme.colors['White'];
      theme.active['background-color'] = theme.colors['Green'];
      return <StyledGreen {...props}>{props.children}</StyledGreen>;

    case ThemeVariant.GRAY:
      theme.active.color = theme.colors['Black'];
      theme.active['background-color'] = theme.colors['50Black'];
      return <StyledGray {...props}>{props.children}</StyledGray>;

    case ThemeVariant.LIGHTGRAY:
      theme.active.color = theme.colors['Black'];
      theme.active['background-color'] = theme.colors['Light Gray'];
      return <StyledGray {...props}>{props.children}</StyledGray>;

    case ThemeVariant.LIGHT:
      theme.active.color = theme.colors['Black'];
      theme.active['background-color'] = theme.colors['Light Gray'];
      return <StyledLight {...props}>{props.children}</StyledLight>;

    case ThemeVariant.LIGHTCREAM:
      theme.active.color = theme.colors['Black'];
      theme.active['background-color'] = theme.colors['Light Cream'];
      return <StyledLightCream {...props}>{props.children}</StyledLightCream>;

    case ThemeVariant.OFFWHITE:
      theme.active.color = theme.colors['Black'];
      theme.active['background-color'] = theme.colors['Off white'];
      return <StyledOffWhite {...props}>{props.children}</StyledOffWhite>;

    case ThemeVariant.LIGHTBROWN:
    default:
      theme.active.color = theme.colors['Black'];
      theme.active['background-color'] = theme.colors['Light Gray'];
      return <StyledCream {...props}>{props.children}</StyledCream>;
  }
};

export interface ThemeProps {
  color?: ThemeColor;
  children: ReactNode;
}

// the main theme element
export const Theme = (props: ThemeProps): JSX.Element => {
  // NOTE: look into theme toggling
  if (props.color) {
    theme.color = props.color;
  }
  const screenSize = useScreenClass();
  const isMobile = screenSize === 'xs' || screenSize === 'sm';
  const isTablet = screenSize === 'md';
  const themeWithAddedProps = { ...theme, isMobile, isTablet, screenSize };
  return <ThemeProvider theme={themeWithAddedProps}>{renderThemeColor(props)}</ThemeProvider>;
};

export default Theme;
