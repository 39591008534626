import styled from 'styled-components';

import { commonInput } from 'src/styles/inputs';
import { rem } from 'src/core/theme/utilities';
import { Body } from '../Typography';

export const InputContainer = styled('input')<{ error?: string }>`
  ${commonInput}
  height: ${rem(64)};
  ${({ theme, error }) =>
    !!error?.length &&
    `
  border-color: ${theme.colors['Red']};
  :focus {
    border-color: ${theme.colors['Red']};
  }
  `}
`;

export const ErrorMessage = styled(Body)`
  color: ${({ theme }) => theme.colors['Red']};
`;
