import { CSSProperties } from 'react';
import styled from 'styled-components';
import { a } from '@react-spring/web';

import { AltruistLogo } from 'src/atoms';
import { flexCenter } from 'src/styles/layout';
import { rem } from 'src/core/theme/utilities';
import { themeColors } from 'src/core/theme/Theme';

export const headerStyles = {
  '--cursor': 'pointer',
} as CSSProperties;

export const HeaderContainer = styled(a.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${rem(35)};
  border-bottom: 1px solid ${themeColors['Medium Beige']};
  height: ${rem('80px')};
`;

export const CloseButtonContainer = styled('button')`
  ${flexCenter}

  background-color: transparent;
  border: none;
  border-radius: 50%;
  height: ${rem(32)};
  padding: 0;
  width: ${rem(32)};

  :hover {
    background-color: ${themeColors['Medium Beige']};
  }

  :active {
    background-color: ${themeColors['Greige']};
  }
`;

export const Logo = styled(AltruistLogo)`
  cursor: var(--cursor);
`;
