import { FC, ReactNode, useState } from 'react';
import { FadeIn } from 'src/atoms/';

import * as Styled from './AccordionStyled';

export * as AccordionStyled from './AccordionStyled';

interface Item {
  header: string | ReactNode;
  content: string | ReactNode;
}

type IconType = 'chevron' | 'check';
export interface AccordionProps {
  iconType?: IconType;
  openInitially?: number;
  items: Item[];
  testId?: string;
}

type IToggleIcon = {
  [key in IconType]: {
    open: JSX.Element;
    close: JSX.Element;
  };
};

const toggleIcon: IToggleIcon = {
  check: {
    open: <Styled.AccordionPlusIcon />,
    close: <Styled.AccordionMinusIcon />,
  },
  chevron: {
    open: <Styled.AccordionChevronDown />,
    close: <Styled.AccordionChevronUp />,
  },
};
export const Accordion: FC<AccordionProps> = ({
  iconType = 'check',
  items,
  openInitially,
  testId = null,
}) => {
  const [openedBlock, setOpenedBlock] = useState<number | undefined>(openInitially || 0);
  const ToggleIcon = toggleIcon[iconType];

  const handleToggle = (index: number) => {
    setOpenedBlock(index === openedBlock && openInitially !== undefined ? undefined : index);
  };
  return (
    <Styled.AccordionContainer data-testid='Accordion-wrapper'>
      {items.map((item: Item, i: number) => {
        return (
          <Styled.AccordionBlock
            key={i}
            $isOpen={openedBlock === i}
            onClick={() => handleToggle(i)}
            $hideLast={!items[i + 1]}
            data-testid={`Accordion-block${i}`}
          >
            <>
              <Styled.AccordionHeader data-testid={`${testId}-${i}`}>
                {typeof item.header === 'string' ? (
                  <Styled.Title noMargin>{item.header}</Styled.Title>
                ) : (
                  item.header
                )}

                {openedBlock === i ? ToggleIcon.close : ToggleIcon.open}
              </Styled.AccordionHeader>
              {openedBlock === i && (
                <FadeIn>
                  <Styled.AccordionContent data-testid={`${testId}-${i}-Content`}>
                    {item.content}
                  </Styled.AccordionContent>
                </FadeIn>
              )}
            </>
          </Styled.AccordionBlock>
        );
      })}
    </Styled.AccordionContainer>
  );
};
