import { FC } from 'react';
import { Card, Pill } from 'src/atoms/Selectable';

import { Option } from 'src/molecules/CheckList/CheckList';

import * as Styled from './SelectableListStyled';

export const testId = 'selectable-list-container';

interface SelectableListProps {
  onChange: (val: Option | Option[]) => void;
  options: Option[];
  value: Option[];
  isColumn?: boolean;
  isPill?: boolean;
  isRadio?: boolean;
}

export const SelectableList: FC<SelectableListProps> = ({
  isColumn = false,
  isPill = false,
  isRadio = false,
  onChange,
  options,
  value,
}) => {
  const selected = value?.map((option) => option?.value) || [];

  const style = !isColumn ? {} : Styled.isColumnStyles;

  const handleChecked = (option: Option) => (checked: boolean) => {
    if (isRadio) {
      onChange(option);
      return;
    }

    if (checked) {
      const newValue = [...value, option];
      onChange(newValue);
      return;
    }

    const newValue = [...value];
    const index = value.findIndex(({ value: optVal }) => option.value === optVal);
    newValue.splice(index, 1);
    onChange(newValue);
  };

  const Selectable = !isPill ? Card : Pill;

  return (
    <Styled.Container data-testid={testId} style={style}>
      {options.map((option) => {
        const isSelected = selected?.includes(option.value);

        return (
          <Selectable
            key={option.value}
            body={option.description}
            isCheckbox={!isRadio}
            isSelected={isSelected}
            title={option.label}
            onChange={handleChecked(option)}
          />
        );
      })}
    </Styled.Container>
  );
};
