import { Container as CContainer } from 'react-grid-system';
import { rem } from 'src/core/theme/utilities';
import styled from 'styled-components';

export const NotFoundSubtitle = styled('p')`
  font-family: ${({ theme }) => theme.textStyles['Body/SML'].font};
  font-size: ${({ theme }) => theme.textStyles['Body/SML'].fontSize};
  font-weight: ${({ theme }) => theme.textStyles['Body/SML'].fontWeight};
  line-height: ${({ theme }) => theme.textStyles['Body/SML'].lineHeight};
  letter-spacing: ${({ theme }) => theme.textStyles['Body/SML'].letterSpacing};
  color: ${({ theme }) => theme.textStyles['Body/SML'].color};
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const Container = styled(CContainer)`
  margin: ${rem(40)} 0 ${rem(40)} 0;
`;

export const LogoContainer = styled.div`
  margin-top: ${rem(38)};
  margin-left: ${rem(63)};
`;

export const LinkContainer = styled.div`
  margin-top: ${rem(46)};
  text-align: center;
  margin-bottom: ${rem(40)};
`;
