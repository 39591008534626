import React, { ComponentType, FC, Ref } from 'react';
import {
  components,
  default as ReactSelect,
  GroupBase,
  OnChangeValue,
  OptionProps,
  StylesConfig,
  ValueContainerProps,
} from 'react-select';
import { default as SelectType } from 'react-select/dist/declarations/src/Select';
import { Checkbox } from 'src/atoms/Checkbox/Checkbox';
import { themeColors } from 'src/core/theme/Theme';
import { rem } from 'src/core/theme/utilities';
import * as Styled from './SelectStyled';

const customStyles: StylesConfig<SelectOption, boolean, GroupBase<SelectOption>> | undefined = {
  dropdownIndicator: (provided) => ({
    ...provided,
    color: themeColors['Dark Gray'],
    padding: 0,
  }),
  option: (provided, state) => {
    const isSelectedOnSingleSelect = state.isSelected && !state.isMulti;
    return {
      ...provided,
      color: isSelectedOnSingleSelect ? themeColors['Light Cream'] : themeColors['Black'],
      background: isSelectedOnSingleSelect ? themeColors['Greige'] : themeColors['Off white'],
      border: 0,
      '&:hover': {
        color: themeColors['Light Cream'],
        background: themeColors['Greige'],
      },
    };
  },
  menu: (provided) => ({
    ...provided,
    background: themeColors['Off white'],
    border: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    background: themeColors['Off white'],
    paddingTop: rem(20),
    paddingBottom: rem(20),
    paddingLeft: rem(24),
    paddingRight: rem(24),
    width: '100%',
    border: state.isFocused ? '1px solid' : '1px solid transparent',
    borderColor: themeColors['Dark Greige'],
    boxShadow: 'none',
    borderRadius: '2px',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: themeColors['Dark Greige'],
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: 'block',
    color: themeColors['Black'],
    background: themeColors['Off white'],
    border: 0,
    fontSize: rem(16),
    padding: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
};

const CustomValueContainer: ComponentType<
  ValueContainerProps<SelectOption, boolean, GroupBase<SelectOption>>
> = (props) => {
  const { getValue, selectProps } = props;
  const { inputValue, placeholder } = selectProps;

  const selected = getValue();
  const children = props.children as React.ReactNode[];
  const additionalText = selected.length > 1 ? `(+${selected.length - 1})` : '';
  const displayText = `${(selected[0] && selected[0].label) || ''} ${additionalText}`;
  return (
    <components.ValueContainer data-testid='value-container' {...props}>
      {!inputValue.length && displayText}
      {!inputValue.length && placeholder}
      {children[1]}
    </components.ValueContainer>
  );
};

const CustomOption: ComponentType<OptionProps<SelectOption, boolean, GroupBase<SelectOption>>> = (
  props,
) => {
  return (
    <components.Option {...props}>
      <Styled.OptionContainer {...props.innerProps} ref={props.innerRef}>
        {props.isMulti && <Checkbox checked={props.isSelected} onChange={() => null} />}
        <Styled.Option>{props.label}</Styled.Option>
      </Styled.OptionContainer>
    </components.Option>
  );
};

export type SelectOption = {
  value: string;
  label: string;
} | null;

export interface SelectProps {
  options: SelectOption[];
  onChange?: (val: OnChangeValue<SelectOption, boolean>) => void;
  value?: SelectOption | SelectOption[];
  ref?: Ref<SelectType<SelectOption, boolean, GroupBase<SelectOption>>>;
  isMulti?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  inputId?: string;
}

export const Select: FC<SelectProps> = React.forwardRef(
  ({ options, placeholder = '', ...props }, ref) => {
    return (
      <ReactSelect
        placeholder={props.value ? '' : placeholder}
        closeMenuOnSelect={false}
        blurInputOnSelect={props.isMulti ? false : true}
        hideSelectedOptions={false}
        options={options}
        styles={customStyles}
        components={{
          ValueContainer: CustomValueContainer,
          Option: CustomOption,
          IndicatorSeparator: () => null,
        }}
        isSearchable={props.isSearchable}
        {...props}
        ref={ref}
      />
    );
  },
);
