import { CSSProperties, FC, ReactNode } from 'react';
import { cssTransition, Id, toast, ToastOptions } from 'react-toastify';

import { Icons, Typography } from 'src/atoms';
import { themeColors } from 'src/core/theme/Theme';

import * as Styled from './ToastStyled';

export const CLOSE_BTN_TEST_ID = 'Toast-Close-Btn';

const slideTransition = cssTransition({
  appendPosition: true,
  enter: `Toastify--animate Toastify__slide-enter`,
  exit: `Toastify--animate Toastify__slide-exit`,
});

const baseOptions: ToastOptions = {
  autoClose: 3500,
  closeButton: false,
  transition: slideTransition,
};

// TODO: add correct styles for different toast states
const successStyles = {
  '--toast-bg-color': themeColors['Green light'],
  '--toast-font-color': themeColors['Dark Green'],
} as CSSProperties;

const errorStyles = {
  '--toast-bg-color': themeColors['Red'],
  '--toast-font-color': themeColors['White'],
} as CSSProperties;

const altruistDefaultStyles = {
  '--toast-bg-color': themeColors['Dark Gray'],
  '--toast-font-color': themeColors['White'],
  '--toast-icon-color': themeColors['Blue'],
} as CSSProperties;

const altruistSuccessStyles = {
  '--toast-icon-color': themeColors['Green Highlight'],
} as CSSProperties;

const altruistErrorStyles = {
  '--toast-icon-color': themeColors['Orange'],
} as CSSProperties;

interface DefaultToastProps {
  closeBtn?: boolean;
  closeToast?: () => void;
  type?: 'success' | 'error';
}

export const Base: FC<DefaultToastProps> = ({ children, closeBtn = true, closeToast, type }) => {
  const style = () => {
    switch (type) {
      case 'error':
        return errorStyles;

      case 'success':
        return successStyles;

      default:
        return {};
    }
  };

  return (
    <Styled.Container style={style()}>
      <Styled.Content>
        <Typography.Body size='SMALL'>{children}</Typography.Body>
        {closeBtn && (
          <Styled.CloseContainer>
            <Icons.CloseIcon dataTestId={CLOSE_BTN_TEST_ID} onClick={closeToast} />
          </Styled.CloseContainer>
        )}
      </Styled.Content>
    </Styled.Container>
  );
};

export const AltruistBase: FC<DefaultToastProps> = ({
  children,
  closeBtn = true,
  closeToast,
  type,
}) => {
  const getStyles = () => {
    switch (type) {
      case 'error':
        return altruistErrorStyles;

      case 'success':
        return altruistSuccessStyles;

      default:
        return {};
    }
  };

  const style = {
    ...altruistDefaultStyles,
    ...getStyles(),
  };

  return (
    <Styled.AltruistContainer style={style}>
      <Styled.AltruistIconContainer>
        <Styled.AltruistIcon />
      </Styled.AltruistIconContainer>
      <Styled.AltruistContent>
        <Typography.Body size='SMALL'>{children}</Typography.Body>
      </Styled.AltruistContent>
      {closeBtn && (
        <Styled.AltruistCloseContainer>
          <Icons.CloseIcon dataTestId={CLOSE_BTN_TEST_ID} size={14} onClick={closeToast} />
        </Styled.AltruistCloseContainer>
      )}
    </Styled.AltruistContainer>
  );
};

type CallToast = (
  content: ReactNode,
  closeBtn?: boolean,
  type?: DefaultToastProps['type'],
  altruist?: boolean,
) => Id;

const callToast: CallToast = (content, closeBtn, type, altruist = false) => {
  const Component = !altruist ? Base : AltruistBase;

  return toast(
    ({ closeToast }) => (
      <Component closeBtn={closeBtn} closeToast={closeToast} type={type}>
        {content}
      </Component>
    ),
    baseOptions,
  );
};

type Toast = (content: ReactNode, closeBtn?: boolean) => Id;

export const Default: Toast = (content, closeBtn = undefined) =>
  callToast(content, closeBtn, undefined);

export const Success: Toast = (content, closeBtn = undefined) =>
  callToast(content, closeBtn, 'success');

export const Error: Toast = (content, closeBtn = undefined) =>
  callToast(content, closeBtn, 'error');

export const isActive = (id?: Id) => toast.isActive(id as Id);

export const AltruistDefault: Toast = (content, closeBtn = undefined) =>
  callToast(content, closeBtn, undefined, true);

export const AltruistSuccess: Toast = (content, closeBtn = undefined) =>
  callToast(content, closeBtn, 'success', true);

export const AltruistError: Toast = (content, closeBtn = undefined) =>
  callToast(content, closeBtn, 'error', true);
