import * as Styled from './FooterStyled';
import { footerLinks } from './footerLinks';
import { Typography } from 'src/atoms';
import { useIsMobile } from 'src/hooks/useIsMobile';
import { Col, Row } from 'react-grid-system';

export const Footer = () => {
  const isMobile = useIsMobile();
  const fullYear = new Date().getFullYear();

  const goToLink = (url: string) => () => {
    window.open(url, '_blank');
  };
  return (
    <Styled.FooterContainer id='match-footer'>
      <Row justify='center'>
        <Col xl={14} md={12}>
          <Styled.SiteLinkRow data-testid='Footer-Nav-Links'>
            <Styled.SiteLinksWrapper id='SiteLinksWrapper'>
              <Styled.SiteLinksContainer id='SiteLinksContainer'>
                {footerLinks.map((link) => (
                  <Styled.FooterLink
                    $isMobile={isMobile}
                    key={link.label}
                    onClick={goToLink(link.url)}
                  >
                    {link.label}
                  </Styled.FooterLink>
                ))}
              </Styled.SiteLinksContainer>
              <Styled.SiteCopyRightContainer id='SiteCopyRightContainer'>
                <Typography.Body size='SMALL'>© {fullYear} Altruist Corp</Typography.Body>
              </Styled.SiteCopyRightContainer>
            </Styled.SiteLinksWrapper>
          </Styled.SiteLinkRow>
          <Styled.LegalBlockRow data-testid='Footer-Legal' justify='between'>
            <Styled.LegalBlock xs={16} md={5}>
              Altruist Corp ("Altruist") offers a software engine designed to help financial
              advisors achieve better outcomes. Advisory services are provided by Altruist LLC, an
              SEC-registered investment adviser, and brokerage related products are provided by
              Altruist Financial LLC, a member of{' '}
              <Styled.LegalBlockLink target='_blank' href='https://www.finra.org/#/'>
                FINRA
              </Styled.LegalBlockLink>
              /
              <Styled.LegalBlockLink target='_blank' href='https://www.sipc.org/'>
                SIPC
              </Styled.LegalBlockLink>
              . Commission-free trading means $0 commission trades placed in brokerage accounts via
              web or mobile devices. Other fees may still apply. Please see the{' '}
              <Styled.LegalBlockLink target='_blank' href='https://altruist.com/legal/'>
                Altruist Financial Fee
              </Styled.LegalBlockLink>{' '}
              Schedule to learn more.
            </Styled.LegalBlock>
            <Styled.LegalBlock xs={16} md={5}>
              By using this website, you understand the information being presented is provided for
              informational purposes only and agree to our{' '}
              <Styled.LegalBlockLink target='_blank' href='https://altruist.com/terms/'>
                Terms of Use
              </Styled.LegalBlockLink>{' '}
              and{' '}
              <Styled.LegalBlockLink target='_blank' href='https://altruist.com/legal/'>
                Privacy Policy
              </Styled.LegalBlockLink>
              . Nothing in this communication should be construed as an offer, recommendation, or
              solicitation to buy or sell any security. Additionally, Altruist or its affiliates do
              not provide tax advice and investors are encouraged to consult with their personal tax
              advisors.
            </Styled.LegalBlock>
            <Styled.LegalBlock xs={16} md={5}>
              All investing involves risk, including the possible loss of money you invest, and past
              performance does not guarantee future performance. Historical returns, expected
              returns, and probability projections are provided for informational and illustrative
              purposes, and may not reflect actual future performance. Clearing and custody of all
              securities are provided by Apex Clearing Corporation.
              {`\n\n`}
              Check the background of Altruist Financial LLC on FINRA's BrokerCheck:{' '}
              <Styled.LegalBlockLink target='_blank' href='https://brokercheck.finra.org/'>
                https://brokercheck.finra.org/
              </Styled.LegalBlockLink>
            </Styled.LegalBlock>
          </Styled.LegalBlockRow>
        </Col>
      </Row>
    </Styled.FooterContainer>
  );
};
