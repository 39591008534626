import styled from 'styled-components';
import { a } from '@react-spring/web';

import { flexCenter } from 'src/styles/layout';
import { rem } from 'src/core/theme/utilities';
import { zIndex, themeColors } from 'src/core/theme/Theme';

const DESKTOP_WIDTH = 1440;
const SIDEDRAWER_WIDTH = 520;
const SideDrawerProportion = window.innerWidth * (SIDEDRAWER_WIDTH / DESKTOP_WIDTH);

export const Header = styled(a.div)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: ${rem(24)} ${rem(32)};
`;

export const CloseButtonContainer = styled('button')`
  ${flexCenter}

  background-color: transparent;
  border: none;
  border-radius: 50%;
  height: ${rem(32)};
  padding: 0;
  width: ${rem(32)};

  :hover {
    background-color: ${themeColors['Medium Beige']};
  }

  :active {
    background-color: ${themeColors['Greige']};
  }
`;

export const SheetContainer = styled(a.div)<{ $isMobile?: boolean }>`
  position: fixed;
  touch-action: none;
  z-index: ${zIndex.mid};
  background: ${({ theme }) => theme.colors['Light Cream']};
  border-radius: ${rem(12)};
  ${({ $isMobile }) =>
    !$isMobile &&
    `
    height: calc(86 * var(--vh));
    top: 3%;
    right: 1%;
    width: ${SideDrawerProportion}px;
    min-width: ${rem(520)};
  `}
`;
export const SheetBody = styled(a.div)`
  height: 86vh;
  overflow: hidden auto;
  border-bottom-left-radius: ${rem(12)};
  border-bottom-right-radius: ${rem(12)};
  ${({ theme: { isMobile } }) => {
    return isMobile && `height: calc(${'95'} * var(--vh));`;
  }}
`;
export const DarkBackground = styled.div`
  top: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.colors['Black']};
  opacity: 0.3;
  overflow: hidden;
`;
