import { themeColors } from 'src/core/theme/Theme';
import { rem } from 'src/core/theme/utilities';
import styled from 'styled-components';

export const Disclosure = styled.div`
  font-size: ${rem(14)};
  line-height: ${rem(32)};
  white-space: pre-line;
  color: ${themeColors['Greige']};
  margin-bottom: ${rem(213)};
  font-size: ${rem(18)};
`;

export const LegalLink = styled.a`
  color: ${themeColors['Greige']};
`;
