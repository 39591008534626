import { Col, Row } from 'react-grid-system';
import styled from 'styled-components';

import { rem } from 'src/core/theme/utilities';
import { Body, BodyStyles } from 'src/atoms/Typography/Body';
import { BodyProps } from 'src/atoms/Typography/sharedTypes';
import { mediaQuery } from 'src/styles/mediaQuery';

// For Devices with sizes less than what our theme provides
const isUnthemedSize = window.innerWidth < 412;
export const FooterContainer = styled.div`
  position: relative;
  padding-bottom: ${({ theme: { isMobile } }) => (isMobile ? rem(112) : rem(46))};
`;

export const FooterLink = styled(Body).attrs<unknown, BodyProps>({
  size: 'SMALL',
})<{ $isMobile?: boolean }>`
  margin-bottom: ${rem(20)};
  margin-right: ${({ $isMobile }) => rem($isMobile ? 20 : 24)};
  white-space: nowrap;
  &:hover {
    cursor: pointer;
  }
`;

export const LegalBlockLink = styled.a`
  font-size: inherit;
  color: inherit;
  line-height: 1.67;
  &:hover {
    text-decoration: none;
  }
`;

export const SiteLinkRow = styled(Row)``;
export const SiteLinkCol = styled(Col)`
  display: flex;
  justify-content: space-between;
`;
export const LegalBlockRow = styled(Row)``;
export const LegalBlock = styled(Col)<{ $isMobile?: boolean }>`
  white-space: pre-wrap;
  margin-bottom: 16px;
  ${BodyStyles({ size: 'CAPTION' })};
`;

export const SiteLinksWrapper = styled('div')`
  display: flex;
  align-items: top;
  width: 100%;
  padding: 0 ${rem(15)} 0 ${rem(15)};
  margin-bottom: ${rem(46)};
  flex-wrap: wrap;
`;

export const SiteLinksContainer = styled('div')`
  flex-wrap: wrap;
  display: inherit;
  flex: 2;
  flex-basis: 100%;

  ${mediaQuery('xsmall')} {
    flex-basis: auto;
  }
`;

export const SiteCopyRightContainer = styled('div')`
  display: inherit;

  ${isUnthemedSize &&
  `
  justify-content: flex-start;
  `}
`;
