import { css } from 'styled-components';

import { rem } from 'src/core/theme/utilities';

export const flex = css`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const flexCenter = css`
  ${flex}

  align-items: center;
  justify-content: center;
`;

export const pagePaddingXCorrect = css`
  padding-left: ${rem(17)};
  padding-right: ${rem(17)};
`;
