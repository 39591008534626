import { FC } from 'react';

import { ThemeColors, themeColors } from 'src/core/theme/Theme';
import { rem } from 'src/core/theme/utilities';
// TODO: integrate size prop into all icons

export interface IconProps {
  color?: ThemeColors;
  className?: string;
  onClick?: () => void;
  size?: number;
  dataTestId?: string;
}

export const ShowAllIcon: FC<IconProps> = ({
  className,
  color = 'Dark Greige',
  dataTestId = 'ShowAllIcon-icon',
}) => {
  return (
    <svg
      data-testid={dataTestId}
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M7 7H9V9H7V7Z' fill={themeColors[color]} />
      <path d='M11 7H13V9H11V7Z' fill={themeColors[color]} />
      <path d='M17 7H15V9H17V7Z' fill={themeColors[color]} />
      <path d='M7 11H9V13H7V11Z' fill={themeColors[color]} />
      <path d='M13 11H11V13H13V11Z' fill={themeColors[color]} />
      <path d='M15 11H17V13H15V11Z' fill={themeColors[color]} />
      <path d='M9 15H7V17H9V15Z' fill={themeColors[color]} />
      <path d='M11 15H13V17H11V15Z' fill={themeColors[color]} />
      <path d='M17 15H15V17H17V15Z' fill={themeColors[color]} />
    </svg>
  );
};

export const CloseIcon: FC<IconProps> = ({
  className,
  color,
  size,
  onClick,
  dataTestId = 'CloseIcon-icon',
}) => {
  return (
    <svg
      data-testid={dataTestId}
      onClick={onClick}
      className={className}
      width={`${size ?? '24'}`}
      height={`${size ?? '24'}`}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z'
        fill={themeColors[color || 'White']}
      />
    </svg>
  );
};

export const SearchIcon: FC<IconProps> = ({ className, dataTestId = 'SearchIcon-icon' }) => {
  return (
    <svg
      data-testid={dataTestId}
      className={className}
      width='20'
      height='20'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.319 14.4326C20.7628 11.2941 20.542 6.75347 17.6569 3.86829C14.5327 0.744098 9.46734 0.744098 6.34315 3.86829C3.21895 6.99249 3.21895 12.0578 6.34315 15.182C9.22833 18.0672 13.769 18.2879 16.9075 15.8442C16.921 15.8595 16.9351 15.8745 16.9497 15.8891L21.1924 20.1317C21.5829 20.5223 22.2161 20.5223 22.6066 20.1317C22.9971 19.7412 22.9971 19.1081 22.6066 18.7175L18.364 14.4749C18.3493 14.4603 18.3343 14.4462 18.319 14.4326ZM16.2426 5.28251C18.5858 7.62565 18.5858 11.4246 16.2426 13.7678C13.8995 16.1109 10.1005 16.1109 7.75736 13.7678C5.41421 11.4246 5.41421 7.62565 7.75736 5.28251C10.1005 2.93936 13.8995 2.93936 16.2426 5.28251Z'
        fill={themeColors['White']}
      />
    </svg>
  );
};

export const ChevronUpIcon: FC<IconProps> = ({
  color = '70Black',
  className,
  dataTestId = 'ChevronUpIcon-icon',
}) => {
  return (
    <svg
      data-testid={dataTestId}
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.6569 16.2427L19.0711 14.8285L12.0001 7.75739L4.92896 14.8285L6.34317 16.2427L12.0001 10.5858L17.6569 16.2427Z'
        fill={themeColors[color]}
      />
    </svg>
  );
};

export const ChevronDownIcon: FC<IconProps> = ({
  className,
  color = '70Black',
  dataTestId = 'ChevronDownIcon-icon',
}) => {
  return (
    <svg
      data-testid={dataTestId}
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.34317 7.75732L4.92896 9.17154L12 16.2426L19.0711 9.17157L17.6569 7.75735L12 13.4142L6.34317 7.75732Z'
        fill={themeColors[color]}
      />
    </svg>
  );
};

export const ChevronLeftIcon: FC<IconProps> = ({
  className,
  color = 'White',
  dataTestId = 'ChevronLeftIcon-icon',
}) => {
  return (
    <svg
      data-testid={dataTestId}
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z'
        fill={themeColors[color]}
      />
    </svg>
  );
};

export const ChevronRightIcon: FC<IconProps> = ({
  className,
  color,
  dataTestId = 'ChevronRightIcon-icon',
}) => {
  return (
    <svg
      data-testid={dataTestId}
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z'
        fill={themeColors[color || 'White']}
      />
    </svg>
  );
};

export const MinusIcon: FC<IconProps> = ({ className, onClick, dataTestId = 'MinusIcon-icon' }) => {
  return (
    <svg
      data-testid={dataTestId}
      onClick={onClick}
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z'
        fill='currentColor'
      />
    </svg>
  );
};

export const PlusIcon: FC<IconProps> = ({ className, onClick, dataTestId = 'PlusIcon-icon' }) => {
  return (
    <svg
      data-testid={dataTestId}
      onClick={onClick}
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5C13 4.44772 12.5523 4 12 4Z'
        fill='currentColor'
      />
    </svg>
  );
};

export const LoadingIcon: FC<IconProps> = ({
  className,
  size = 50,
  dataTestId = 'LoadingIcon-icon',
}) => {
  return (
    <svg
      data-testid={dataTestId}
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      style={{
        margin: 'auto',
        background: 'transparent',
        display: 'block',
        shapeRendering: 'auto',
      }}
      width={rem(size)}
      height={rem(size)}
      viewBox='0 0 100 100'
      preserveAspectRatio='xMidYMid'
    >
      <circle
        cx='50'
        cy='50'
        fill='none'
        stroke={themeColors['Dark Greige']}
        strokeWidth='10'
        r='35'
        strokeDasharray='164.93361431346415 56.97787143782138'
      >
        <animateTransform
          attributeName='transform'
          type='rotate'
          repeatCount='indefinite'
          dur='1s'
          values='0 50 50;360 50 50'
          keyTimes='0;1'
        ></animateTransform>
      </circle>
    </svg>
  );
};

export const CaretRight: FC<IconProps> = ({
  className,
  onClick,
  size = 14,
  dataTestId = 'CaretRight-icon',
}) => {
  return (
    <svg
      data-testid={dataTestId}
      className={className}
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M9 17.898C9 18.972 10.2649 19.546 11.0731 18.8388L17.3838 13.3169C18.1806 12.6197 18.1806 11.3801 17.3838 10.6829L11.0731 5.16108C10.2649 4.45388 9 5.02785 9 6.1018V17.898Z'
        fill='#212121'
      />
    </svg>
  );
};

export const Checkmark = ({ ...props }) => {
  return (
    <svg
      data-testid={props.dataTestId || 'Checkmark-svg'}
      width='11'
      height='8'
      viewBox='0 0 11 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1 4L4 7L10 1'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const InfoIconCircle = ({ ...props }) => (
  <svg
    data-testid={props.dataTestId || 'InfoIconCircle-svg'}
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='8' cy='8' r='8' fill='rgba(255, 255, 255, 0)' />
    <path
      d='M8 1C11.86 1 15 4.14 15 8C15 11.86 11.86 15 8 15C4.14 15 1 11.86 1 8C1 4.14 4.14 1 8 1ZM8 0C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16C12.42 16 16 12.42 16 8C16 3.58 12.42 0 8 0Z'
      fill='#474E51'
      id='circle-border'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 7.99023C8 9.39023 8 10.8002 8 10.8002V11.5002'
      fill='#474E51'
    />
    <path
      d='M8 7.99023C8 9.39023 8 10.8002 8 10.8002V11.5002'
      stroke='#474E51'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      id='i-line'
    />
    <path
      d='M8.48047 4.99023C8.48047 5.26638 8.25661 5.49023 7.98047 5.49023C7.70433 5.49023 7.48047 5.26638 7.48047 4.99023C7.48047 4.71409 7.70433 4.49023 7.98047 4.49023C8.25661 4.49023 8.48047 4.71409 8.48047 4.99023Z'
      fill='#474E51'
      stroke='#474E51'
      id='i-dot'
    />
  </svg>
);
export const SelectCarotDown: FC<IconProps> = ({ color = 'Black', className, onClick }) => {
  return (
    <svg
      height='20'
      width='20'
      viewBox='0 0 20 20'
      aria-hidden='true'
      focusable='false'
      onClick={onClick}
      className={className}
    >
      <path
        fill={themeColors[color]}
        d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'
      ></path>
    </svg>
  );
};

export const LockIcon: FC<IconProps> = ({ color = 'Greige', className, onClick }) => {
  return (
    <svg
      width='16'
      height='24'
      viewBox='0 0 16 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={className}
    >
      <path
        d='M13 9.761V5C13 2.239 10.762 0 8 0C5.237 0 3 2.239 3 5V9.761C1.173 11.227 0 13.475 0 16C0 20.418 3.582 24 8 24C12.418 24 16 20.418 16 16C16 13.475 14.827 11.227 13 9.761ZM8 18C6.896 18 6 17.104 6 16C6 14.896 6.896 14 8 14C9.104 14 10 14.896 10 16C10 17.104 9.104 18 8 18ZM11 8.587C10.073 8.211 9.062 8 8 8C6.938 8 5.927 8.211 5 8.587V5C5 3.346 6.346 2 8 2C9.654 2 11 3.346 11 5V8.587Z'
        fill={themeColors[color]}
      />
    </svg>
  );
};
