import { createGlobalStyle } from 'styled-components';

import { theme, zIndex } from 'src/core/theme/Theme';
import { mediaQuery } from './mediaQuery';

export const GlobalStyle = createGlobalStyle`
  body {
    background: ${theme.colors['Cream']};
    height: 100vh;
    overflow: hidden auto;
    width: 100vw;
  }

  #root {
    min-height: 100%;
    width: 100%;
    z-index: ${zIndex.min};
  }

  #portal-bottom-bar {
    z-index: ${zIndex.max};
  }

  button {
    transition: all .1s ease-out;  

    &:hover {
      cursor: pointer;
    }

    &:active {
      transform: scale(.95);
    }
  }

  .Toastify__toast-container {
    width: auto;
    z-index: ${zIndex.mid};
  }

  .Toastify__toast {
    border-radius: 0;
    margin: 0;
    padding: 0;

    ${mediaQuery('small')} {
      border-radius: 3px;
    }

    .Toastify__toast-body {
      margin: 0;
      padding: 0;
    }
  }
`;
