import { ClassAttributes, ImgHTMLAttributes } from 'react';

import Logo from 'src/assets/images/Altruist.svg';

export const AltruistLogo = (
  props: JSX.IntrinsicAttributes &
    ClassAttributes<HTMLImageElement> &
    ImgHTMLAttributes<HTMLImageElement>,
) => {
  return <img data-testid='AltruistLogoWrapper' src={Logo} {...props} alt='Altruist Logo' />;
};
