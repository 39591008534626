import { useEffect, useState } from 'react';
import { LocalStorageKeys, LocatStorageValue } from 'src/utils/helpers/localStorageHelpers';

export const usePersistedState = (
  storageKey: LocalStorageKeys,
  fallbackState?: LocatStorageValue,
) => {
  let initialValues;
  try {
    initialValues = localStorage.getItem(storageKey)
      ? JSON.parse(localStorage.getItem(storageKey) as string)
      : fallbackState;
  } catch (e) {
    initialValues = {};
    console.error(e);
  }
  const [value, setValue] = useState(initialValues);

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue];
};
