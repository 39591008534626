import { theme } from 'src/core/theme/Theme';
import { rem } from 'src/core/theme/utilities';
import styled from 'styled-components';

export const Label = styled.div`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: ${rem(14)};
  line-height: ${rem(16)};
  letter-spacing: 0.2px;

  /* Brand/Black */

  color: ${theme.colors['Black']};
`;
