export const footerLinks = [
  {
    label: 'Help',
    url: 'https://help.altruist.com',
  },
  {
    label: 'Security',
    url: 'https://altruist.com/security/',
  },
  {
    label: 'Legal',
    url: 'https://altruist.com/legal/',
  },
  {
    label: 'Terms',
    url: 'https://altruist.com/terms/',
  },
  {
    label: 'Privacy Policy',
    url: 'https://altruist.com/m/Altruist-Privacy-Notice-Privacy-Policy-2022.04.13.pdf',
  },
];
