import { CSSProperties, FC, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { Button } from 'src/atoms';
import { ButtonProps } from 'src/atoms/Button/Button';
import { bottomBarMobileHeight, themeColors, ThemeColors } from 'src/core/theme/Theme';
import { useScrollTrigger } from 'src/hooks/useScrollTrigger';
import { BOTTOM_BAR_IS_VISIBLE } from 'src/styles/classNames';

import * as Styled from './MobileStickyBottomButtonBarStyled';

export interface MobileStickyBottomButtonBarProps extends ButtonProps {
  buttonText: string;
  bgColor?: ThemeColors;
  scrollDistance?: number;
  showOnScroll?: boolean;
}

export const MobileStickyBottomButtonBar: FC<MobileStickyBottomButtonBarProps> = ({
  bgColor,
  buttonText,
  children,
  onClick,
  scrollDistance = 1200,
  showOnScroll,
  ...props
}) => {
  const show = useScrollTrigger(!showOnScroll ? 0 : scrollDistance);

  useEffect(() => {
    if (showOnScroll) {
      if (show) {
        document.body.classList.add(BOTTOM_BAR_IS_VISIBLE);
      } else {
        document.body.classList.remove(BOTTOM_BAR_IS_VISIBLE);
      }
    } else {
      document.body.classList.add(BOTTOM_BAR_IS_VISIBLE);
    }

    return () => {
      document.body.classList.remove(BOTTOM_BAR_IS_VISIBLE);
    };
  }, [show]);

  const bgStyle = !bgColor ? {} : { '--bg-color': themeColors[bgColor] as CSSProperties };

  const scrollStyle = !showOnScroll
    ? {}
    : ({
        '--opacity': 0,
        '--bottom-offset': `-${bottomBarMobileHeight}`,
      } as CSSProperties);

  return createPortal(
    <Styled.BottomButtonBar
      className={show ? 'scrolled' : ''}
      data-testid='MobileStickyBottomButtonBar-wrapper'
      style={{
        ...bgStyle,
        ...scrollStyle,
      }}
    >
      <Styled.ButtonWrapper>
        <Button onClick={onClick} {...props}>
          {buttonText}
        </Button>
      </Styled.ButtonWrapper>
    </Styled.BottomButtonBar>,
    document.getElementById('portal-bottom-bar') as HTMLDivElement,
  );
};
