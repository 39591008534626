import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import { bottomBarMobileHeight } from 'src/core/theme/Theme';
import { rem } from 'src/core/theme/utilities';
import { BOTTOM_BAR_IS_VISIBLE } from 'src/styles/classNames';
import { flexCenter } from 'src/styles/layout';
import { mediaQuery } from 'src/styles/mediaQuery';

export const Container = styled('div')`
  ${flexCenter}

  background-color: var(--toast-bg-color, ${(props) => props.theme.colors['Green light']});
  min-height: ${rem(80)};
`;

export const Content = styled('div')`
  align-items: flex-start;
  display: inherit;
  justify-content: space-between;
  padding: ${rem(16)} ${rem(32)};
  width: inherit;

  ${Container} & > * {
    color: var(--toast-font-color, ${(props) => props.theme.colors['Dark Green']});
  }
`;

export const CloseContainer = styled('div')`
  margin-left: ${rem(24)};

  svg {
    height: 1rem;
    width: 1rem;
  }

  path {
    fill: var(--toast-font-color, ${(props) => props.theme.colors['95Black']});
  }
`;

export const ToasterContainer = styled(ToastContainer)`
  .${BOTTOM_BAR_IS_VISIBLE} & {
    bottom: ${bottomBarMobileHeight};
  }
`;

export const AltruistContainer = styled(Container)`
  background-color: var(--toast-bg-color);
  padding-left: ${rem(8)};
  padding-right: ${rem(8)};
  width: calc(100vw - ${rem(16)});

  ${mediaQuery('small')} {
    max-width: ${rem(776)};
    padding-left: ${rem(32)};
    padding-right: ${rem(32)};
    width: auto;
  }

  > * + * {
    flex: 0 1 auto;
    margin-left: ${rem(16)};
  }
`;

export const AltruistIconContainer = styled('div')`
  align-items: inherit;
  display: inherit;
  min-height: inherit;
  width: ${rem(16)};
`;

export const AltruistIcon = styled('div')`
  background-color: var(--toast-icon-color);
  border-radius: 50%;
  height: ${rem(16)};
  width: ${rem(16)};
`;

export const AltruistContent = styled('div')`
  align-items: inherit;
  display: inherit;
  flex: 10;

  ${AltruistContainer} & > * {
    color: var(--toast-font-color);
  }
`;

export const AltruistCloseContainer = styled('div')`
  align-items: center;
  display: flex;
  min-height: inherit;
  width: ${rem(14)};

  path {
    fill: var(--toast-font-color);
  }
`;
