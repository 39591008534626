import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseButtonContainer } from 'src/atoms/ActionSheet/ActionSheetStyled';
import { CloseIcon } from 'src/atoms/Icons/Icons';

import * as Styled from './HeaderLogoStyled';

export const testId = 'HeaderLogo-id';

interface HeaderLogoProps {
  onClose?: () => void;
}
export const HeaderLogo: FC<HeaderLogoProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const redirectToHome = () => {
    navigate(`/`);
  };
  return (
    <Styled.LogoContainer>
      <Styled.HomeLogo data-testid={testId} onClick={redirectToHome} />
      {onClose && (
        <CloseButtonContainer onClick={onClose}>
          <CloseIcon size={16} color='Black' />
        </CloseButtonContainer>
      )}
    </Styled.LogoContainer>
  );
};
