import styled, { css } from 'styled-components';
import { HeaderProps } from './sharedTypes';

export const Header4 = styled.h4<HeaderProps>`
  font-family: ${({ theme }) => theme.textStyles['Section/Heading — H4'].font};
  font-weight: ${({ theme }) => theme.textStyles['Section/Heading — H4'].fontWeight};
  line-height: ${({ theme }) => theme.textStyles['Section/Heading — H4'].lineHeight};
  letter-spacing: ${({ theme }) => theme.textStyles['Section/Heading — H4'].letterSpacing};
  color: ${({ theme, color = 'Dark Greige' }) => theme.colors[`${color}`]};
  font-size: ${({ theme }) => theme.textStyles['Section/Heading — H4'].fontSize};
  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0;
    `};
`;
