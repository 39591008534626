import styled from 'styled-components';

import { bottomBarMobileHeight, themeColors, zIndex } from 'src/core/theme/Theme';
import { rem } from 'src/core/theme/utilities';
import { transitionDefault } from 'src/styles/animation';
import { flexCenter } from 'src/styles/layout';

export const BottomButtonBar = styled('div')`
  ${flexCenter}
  ${transitionDefault}

  background-color: var(--bg-color, ${themeColors['Off white']});
  border-top: 1px solid ${themeColors['Medium Beige']};
  bottom: var(--bottom-offset, 0);
  height: ${bottomBarMobileHeight};
  opacity: var(--opacity, 1);
  position: fixed;
  z-index: ${zIndex.max};

  &.scrolled {
    bottom: 0;
    opacity: 1;
  }
`;

export const ButtonWrapper = styled('div')`
  padding: ${rem(27)} ${rem(32)};
  width: inherit;
  display: flex;
  justify-content: center;

  > button {
    font-size: 1rem;
    font-weight: ${(props) => props.theme.fontWeights[1]};
    height: ${rem(58)};
    padding: 0;
    width: 100%;
    max-width: ${rem(364)};
  }
`;
