import { FC } from 'react';
import * as Styled from './BannerStyled';
import { CloseIcon } from 'src/atoms/Icons/Icons';

interface BannerProps {
  children: React.ReactNode;
  isCloseable?: boolean;
  onClose?: () => void;
}

export const Banner: FC<BannerProps> = ({ children, isCloseable, onClose }) => {
  return (
    <Styled.Container data-testid='Banner-wrapper' isCloseable={isCloseable}>
      <Styled.BannerText size='SMALL'>{children}</Styled.BannerText>
      {isCloseable && (
        <Styled.InfoIconContainer>
          <CloseIcon size={14} color='Black' onClick={onClose} />
        </Styled.InfoIconContainer>
      )}
    </Styled.Container>
  );
};
