import styled, { css } from 'styled-components';
import { themeColors } from 'src/core/theme/Theme';
import { rem } from 'src/core/theme/utilities';
import { Icons, Typography } from 'src/atoms';

export const AccordionContainer = styled.div``;
export const AccordionBlock = styled.div<{ $hideLast?: boolean; $isOpen?: boolean }>`
  margin-bottom: ${rem(30)};
  border-bottom: ${rem(1)} solid ${themeColors['30Black']};
  ${({ $hideLast }) =>
    $hideLast &&
    css`
      border: 0;
    `}
  &:hover {
    cursor: ${({ $isOpen }) => ($isOpen ? 'default' : 'pointer')};
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${rem(30)};
`;

export const Title = styled(Typography.Header4)``;

export const AccordionPlusIcon = styled(Icons.PlusIcon)`
  &:hover {
    cursor: pointer;
  }
`;
export const AccordionMinusIcon = styled(Icons.MinusIcon)``;
export const AccordionChevronUp = styled(Icons.ChevronUpIcon)`
  &:hover {
    cursor: pointer;
  }
`;
export const AccordionChevronDown = styled(Icons.ChevronDownIcon)``;
export const AccordionContent = styled(Typography.Header4)`
  color: ${themeColors['Greige']};
  margin-bottom: ${rem(30)};
`;
