import { size as themeSize } from 'src/core/theme/utilities';

type MediaQuery = (minSize?: keyof typeof themeSize, maxSize?: keyof typeof themeSize) => string;

export const mediaQuery: MediaQuery = (minSize, maxSize) => {
  if (maxSize) {
    if (minSize) {
      return `@media (min-width: ${themeSize[minSize]}px) and (max-width: ${themeSize[maxSize]}px)`;
    }

    return `@media (max-width: ${themeSize[maxSize]}px)`;
  }

  if (minSize) {
    return `@media (min-width: ${themeSize[minSize]}px)`;
  }

  throw new Error('needs some sizes');
};
