import styled from 'styled-components';
import { themeColors } from 'src/core/theme/Theme';

import { rem } from 'src/core/theme/utilities';

export const CircleIconContainer = styled.div<{ backgroundColor?: string }>`
  width: ${rem(34)};
  height: ${rem(34)};
  margin-left: ${rem(-44)};
  background: ${({ backgroundColor = themeColors['Dark Green'] }) => backgroundColor};
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: ${rem(50)};
`;
