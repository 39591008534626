import { FC } from 'react';

import { LoadingIcon } from 'src/atoms/Icons/Icons';
import * as Styled from './ButtonStyled';

export type BUTTON_SIZES = 'LRG' | 'SML' | 'DEFAULT';

export type BUTTON_COLOR = 'Dark Green' | 'Griege' | 'Beige' | 'Bright Green';

export interface ButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  loading?: boolean;
  variant?: BUTTON_SIZES;
  color?: BUTTON_COLOR;
}

// TODO: Clean up this atom
export const Button: FC<ButtonProps> = ({
  onClick,
  disabled = false,
  loading,
  className,
  children,
  color = 'Dark Green',
  variant = 'LRG',
}) => {
  return (
    <Styled.FlatButton
      onClick={onClick}
      className={className}
      variant={variant}
      disabled={disabled || !!loading}
      color={color}
    >
      {loading ? <LoadingIcon size={20} /> : children}
    </Styled.FlatButton>
  );
};
