import styled from 'styled-components';

import { rem } from 'src/core/theme/utilities';

export const ImageContainer = styled('div')<{ $width?: number }>`
  margin-bottom: 1rem;
  width: ${({ $width }) => rem($width || 88)};

  > img {
    border-radius: ${rem(8)};
    max-width: 100%;
  }
`;

export const SkeletonLine = styled('div')`
  background-color: ${(props) => props.theme.colors['Medium Beige']};
  height: ${rem(7)};
  width: var(--width, 100%);

  :last-of-type {
    margin-top: ${rem(7)};
  }
`;
