import { FC } from 'react';

import { CloseIcon, ChevronLeftIcon } from 'src/atoms/Icons/Icons';

import * as Styled from './HeaderStyled';

interface HeaderProps {
  onBack?: () => void;
  onClick?: boolean;
  onClose?: () => void;
  'data-testid'?: string;
}

export const Header: FC<HeaderProps> = ({ onBack, onClick = false, onClose, ...rest }) => {
  const redirectToHome = () => {
    window.location.replace('/match');
  };

  const style = !onClick ? {} : Styled.headerStyles;

  return (
    <Styled.HeaderContainer
      data-testid={`Header${rest['data-testid'] ? `-${rest['data-testid']}` : ''}`}
    >
      {onBack && (
        <Styled.CloseButtonContainer data-testid='Header-back' onClick={onBack as never}>
          <ChevronLeftIcon size={6} color='Black' />
        </Styled.CloseButtonContainer>
      )}

      <Styled.Logo style={style} onClick={!onClick ? undefined : redirectToHome} />

      {onClose && (
        <Styled.CloseButtonContainer data-testid='Header-close' onClick={onClose as never}>
          <CloseIcon size={16} color='Black' />
        </Styled.CloseButtonContainer>
      )}
    </Styled.HeaderContainer>
  );
};
