import React from 'react';
import * as Styled from './CheckboxStyled';
export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  ref?: React.Ref<HTMLInputElement>;
}

export const Checkbox: React.FC<CheckboxProps> = React.forwardRef((props, ref) => {
  return (
    <Styled.Container data-testid='Checkbox-wrapper'>
      <Styled.CheckBox {...props} ref={ref} type='checkbox' />
      <Styled.StyledIcon />
    </Styled.Container>
  );
});
