import { datadogRum } from '@datadog/browser-rum';
import { environment } from 'src/apollo-client';

export const monitorInit = () => {
  datadogRum.init({
    applicationId: 'ed99db80-7bd1-4f2a-b1b1-eb4b0129d91b',
    clientToken: 'pube35db826f6a6757e41c48b61374928d2',
    site: 'datadoghq.com',
    service: 'match-frontend',
    env: environment,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.startSessionReplayRecording();
};
