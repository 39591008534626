import styled, { css } from 'styled-components';

import { Body as TBody } from 'src/atoms/Typography/Body';
import { rem } from 'src/core/theme/utilities';
import { flexCenter } from 'src/styles/layout';

const overflowStyles = css`
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors['White']};
  border-radius: ${rem(8)};
  padding: 0px;
  width: ${rem(364)};
  height: ${rem(355)};
  &:hover {
    cursor: pointer;
  }
`;

export const Row = styled.div`
  padding: ${rem(24)};
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
`;

export const ImageContainer = styled('div')`
  ${flexCenter}

  height: ${rem(75)};
  width: ${rem(75)};

  > img {
    height: 100%;
    min-width: 100%;
    object-fit: cover;
    border-radius: ${rem(8)};
  }
`;

export const TitleContainer = styled('div')`
  font-size: ${rem(18)};
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: ${rem(21)};
  padding-right: ${rem(24)};
`;

export const Title = styled.div`
  ${overflowStyles}
  -webkit-line-clamp: 2;
  max-height: ${rem(42)};
  color: ${({ theme }) => theme.colors['Black']};
`;

export const SubTitle = styled.div`
  ${overflowStyles}
  -webkit-line-clamp: 2;
  max-height: ${rem(42)};
  margin-top: ${rem(4)};
  color: ${({ theme }) => theme.colors['Greige']};
`;

export const Body = styled.div`
  // Custom Figma
  padding: ${rem(24)};
  background: ${({ theme }) => theme.colors['White']};
  flex: 1;
`;

export const BodyText = styled(TBody)`
  ${overflowStyles}
  -webkit-line-clamp: 2;
  max-height: ${rem(72)};
  color: ${({ theme }) => theme.colors['Black']};
`;

export const FooterContainer = styled.div`
  justify-self: flex-end;
`;

export const Footer = styled.div`
  padding: 0 ${rem(24)} ${rem(16)};
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  letter-spacing: 0.2px;
  align-items: center;
  position: relative;
  color: ${({ theme }) => theme.colors['Greige']};
`;

export const FooterButton = styled.div`
  text-decoration: underline;

  :hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

export const CtaButton = styled.button`
  background: transparent;
  width: ${rem('180px')};
  height: ${rem('48px')};
  border: ${rem('2px')} solid ${({ theme }) => theme.colors['Bright Green']};
  border-radius: ${rem('500px')};
  color: ${({ theme }) => theme.colors['Bright Green']};
`;

export const ProfileButton = styled.div`
  color: ${({ theme }) => theme.colors['Greige']};
  font-style: normal;
  font-weight: 400;
  font-size: ${rem('14px')};
  line-height: ${rem('24px')};
  width: ${rem('43px')};
  height: ${rem('24px')};
  :hover {
    cursor: pointer;
    text-decoration: none;
  }
`;
export const ProfileButonContainer = styled.div`
  display: flex;
`;
