import { FC } from 'react';

import * as Styled from './CircleIconStyled';

export interface CircleIconProps {
  className?: string;
  onClick?: () => void;
  backgroundColor?: string;
}

export const CircleIcon: FC<CircleIconProps> = ({
  className,
  onClick,
  backgroundColor,
  children,
}) => {
  return (
    <Styled.CircleIconContainer
      className={className}
      onClick={onClick}
      backgroundColor={backgroundColor}
    >
      {children}
    </Styled.CircleIconContainer>
  );
};
