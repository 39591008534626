import styled, { css } from 'styled-components';
import { HeaderProps } from './sharedTypes';

export const Header3 = styled.h3<HeaderProps>`
  font-family: ${({ theme }) => theme.textStyles['Section/Title — H3'].font};
  font-weight: ${({ theme }) => theme.textStyles['Section/Title — H3'].fontWeight};
  line-height: ${({ theme }) => theme.textStyles['Section/Title — H3'].lineHeight};
  letter-spacing: ${({ theme }) => theme.textStyles['Section/Title — H3'].letterSpacing};
  color: ${({ theme, color = 'Dark Greige' }) => theme.colors[`${color}`]};
  font-size: ${({ theme }) => theme.textStyles['Section/Title — H3'].fontSize};
  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0;
    `};
`;
